import Vue                       from 'vue'
import Vuex                      from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import piPortfoliosApi           from '@/api/v1/pi_portfolios'
import cloneDeep                 from 'lodash/cloneDeep'
import dayjs                     from 'dayjs'
const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

Vue.use(Vuex)

const emptyPiDiv = {
  id: 0,
  createdOn: dayjs().format('YYYY-MM-DD'),
  shareCode: '',
  name: '',
  dividend: 0,
}

const emptyPiFill = {
  id: 0,
  createdOn: dayjs().format('YYYY-MM-DD'),
  shareCode: '',
  name: '',
  action: 'buy',
  quantity: 0,
  price: 0,
  fee: 0,
  tax: 0,
  fillType: '',
  transactionValue: 0,
}

const newPiPortfolio = {
  id: 0,
  shareCode: '',
  name: '',
  firstBuyDate: '',
  latestSellDate: '',
  investmentQuantity: 0,
  investmentValue: 0,
  investmentPrice: 0,
  collectQuantity: 0,
  collectPrincipal: 0,
  collectGain: 0,
  bookPrice: 0,
  investmentId: 0,
  accountingType: '',
  name2: '',
  holdingQuantity: 0,
  totalIssueQuantity: 0,
  ownershipRatio: 0.0,
  unrealizedGainRate: 0,
  principalBalance: 0,
  acquiredValue: 0,
  unrealizedGain: 0,
}

const state = () => ({
  // PiDivs Data
  tmsPiDivs: [],
  selectedDiv: null,
  emptyDiv: cloneDeep(emptyPiDiv),

  // PiFills Data
  tmsPiFills: [],
  selectedFill: null,
  actionTypes: [
    'buy',
    'sell',
  ],
  fillTypes: [
    { name: '체결', value: 'Fill' },
    { name: '이벤트', value: 'CorpAct' },
    { name: '실시간익일', value: 'RtT1' },
    { name: '선물롤오버', value: 'RollOver' },
    { name: '블록딜', value: 'BlockDeal' },
    { name: '재투자', value: 'ReInv' },
  ],
  emptyFill: cloneDeep(emptyPiFill),

  // PiPortfolios Data
  piPortfolios: [],
  drilldownPiPortfolio: cloneDeep (newPiPortfolio),
})

const getters = {
  portfolioName: state => {
    return state.drilldownPiPortfolio.name ? state.drilldownPiPortfolio.name : ''
  },
  getField,
}

const actions = {
  // PiDivs Actions
  getTmsPiDivs ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      piPortfoliosApi.getTmsPiDivs().then(resp => {
        let fromApi = camelcaseKeys(resp, { deep: true })
        commit('setTmsPiDivs', fromApi)
        dispatch('deactiveLoading', null, { root : true })
        resolve(fromApi)
      })
    })
  },
  createDividend ({ commit, dispatch }, div) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let paramDiv = snakecaseKeys(div, { root: true })
      if (paramDiv) {
        piPortfoliosApi.postTmsPiDiv(paramDiv).then(resp => {
          let fromApi = camelcaseKeys(resp, { deep: true })
          commit('createTmsPiDivs', fromApi)
          dispatch('deactiveLoading', null, { root : true })
          resolve(fromApi)
        })
      }
    })
  },
  updateDividend ({ commit, dispatch}, div) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let snakecaseDiv = snakecaseKeys(div, { root: true })
      if (snakecaseDiv) {
        piPortfoliosApi.patchTmsPiDiv(snakecaseDiv).then(resp => {
          let fromApi = camelcaseKeys(resp, { deep: true })
          commit('updateTmsPiDivs', fromApi)
          dispatch('deactiveLoading', null, { root : true })
          resolve(fromApi)
        })
      }
    })
  },
  deleteDividend ({ commit, dispatch }, divId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      piPortfoliosApi.deleteTmsPiDiv(divId).then(resp => {
        commit('removeTmsPiDivs', divId)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },

  // PiFills Actions
  getTmsPiFills ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      piPortfoliosApi.getTmsPiFills().then(resp => {
        let fromApi = camelcaseKeys(resp, { deep: true })
        commit('setTmsPiFills', fromApi)
        dispatch('deactiveLoading', null, { root : true })
        resolve(fromApi)
      })
    })
  },
  createFill ({ commit, dispatch }, fill) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let paramFill = snakecaseKeys(fill, { root: true })
      if (paramFill) {
        piPortfoliosApi.postTmsPiFill(paramFill).then(resp => {
          let fromApi = camelcaseKeys(resp, { deep: true })
          commit('createTmsPiFills', fromApi)
          dispatch('deactiveLoading', null, { root : true })
          resolve(fromApi)
        })
      }
    })
  },
  updateFill ({ commit, dispatch }, fill) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let snakecaseFill = snakecaseKeys(fill, { root: true })
      if (snakecaseFill) {
        piPortfoliosApi.patchTmsPiFill(snakecaseFill).then(resp => {
          let fromApi = camelcaseKeys(resp, { deep: true })
          commit('updateTmsPiFills', fromApi)
          dispatch('deactiveLoading', null, { root : true })
          resolve(fromApi)
        })
      }
    })
  },
  deleteFill ({ commit, dispatch }, fillId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      piPortfoliosApi.deleteTmsPiFill(fillId).then(resp => {
        commit('removeTmsPiFills', fillId)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },

  // PiPortfolio Actions
  getPiPortfolios ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      piPortfoliosApi.getPiPortfolios().then(resp => {
        let fromApi = camelcaseKeys(resp, { deep: true })
        commit('setPiPortfolios', fromApi)
        dispatch('deactiveLoading', null, { root : true })
        resolve(fromApi)
      })
    })
  },
  setBookPrice ({ state, commit, dispatch}, investmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let found = state.piPortfolios.find(investment => investment.id === investmentId)
      let investment = snakecaseKeys(found, { root: true })
      if (investment) {
        piPortfoliosApi.patchPiPortfoliosBookPrice(investment).then(resp => {
          let fromApi = camelcaseKeys(resp, { deep: true })
          commit('updateBookPrice', fromApi)
          dispatch('deactiveLoading', null, { root : true })
          resolve(fromApi)
        })
      }
    })
  },
  calculatePiPortfolio ({ dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      piPortfoliosApi.calculatePiPortfolio().then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  piPortfolioDetailsOpen ({ commit, dispatch }, portfolio) {
    commit('setDrilldownPiPortfolio', portfolio)
    dispatch('sidepanelOpen', { componentName: 'PiPortfolioDetails', directory: 'views/portfolio' }, { root : true })
  },
}

const mutations = {
  // PiDivs mutataions
  setTmsPiDivs (state, fromApi) {
    state.tmsPiDivs = fromApi
  },
  createTmsPiDivs (state, createdDiv) {
    state.tmsPiDivs.push(createdDiv)
    state.selectedDiv = createdDiv
    state.emptyDiv = cloneDeep(emptyPiDiv)
  },
  updateTmsPiDivs (state, updatedDiv) {
    const index = state.tmsPiDivs.findIndex(div => div.id === updatedDiv.id)
    if (index >= 0) {
      state.tmsPiDivs[index] = updatedDiv
    }
  },
  removeTmsPiDivs (state, divId) {
    const index = state.tmsPiDivs.findIndex(div => div.id === divId)
    if (index >= 0) {
      state.tmsPiDivs.splice(index, 1)
    }
  },

  // PiFills mutations
  setTmsPiFills (state, fromApi) {
    state.tmsPiFills = fromApi
  },
  createTmsPiFills (state, createdFill) {
    state.tmsPiFills.push(createdFill)
    state.selectedFill = createdFill
    state.emptyFill = cloneDeep(emptyPiFill)
  },
  updateTmsPiFills (state, updatedFill) {
    const index = state.tmsPiFills.findIndex(fill => fill.id === updatedFill.id)
    if (index >= 0) {
      state.tmsPiFills[index] = updatedFill
    }
  },
  removeTmsPiFills (state, fillId) {
    const index = state.tmsPiFills.findIndex(fill => fill.id === fillId)
    if (index >= 0) {
      state.tmsPiFills.splice(index, 1)
    }
  },

  // PiPortfolios mutations
  setPiPortfolios (state, fromApi) {
    state.piPortfolios = fromApi
  },
  updateBookPrice (state, updatedInvestment) {
    let index = state.piPortfolios.findIndex(investment => investment.id === updatedInvestment.id)
    if (index >= 0) {
      state.piPortfolios[index].bookPrice = updatedInvestment.bookPrice
    }
  },
  setDrilldownPiPortfolio (state, portfolio) {
    // because portfolio is mapMultiRowFields (empty Object)
    let found = state.piPortfolios.find(port => port.id === portfolio.id)
    state.drilldownPiPortfolio = found
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
