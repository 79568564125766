import Vue      from 'vue'
import Vuex     from 'vuex'
import EventBus from '@/utils/event-bus'
import approvals                from './modules/approvals'
import brokers                  from './modules/brokers'
import brokerAccounts           from './modules/broker_accounts'
import changeLogs               from './modules/change_logs'
import companySettings          from './modules/company_settings'
import contacts                 from './modules/contacts'
import corporateAnnouncements   from './modules/corporate_announcements'
import customFields             from './modules/custom_fields'
import dailyPerformances        from './modules/daily_performances'
import dashboardViews           from './modules/dashboard_views'
import entities                 from './modules/entities'
import folders                  from './modules/folders'
import funds                    from './modules/funds'
import investmentEvents         from './modules/investment_events'
import legalFunds               from './modules/legal_funds'
import investments              from './modules/investments'
import investmentPlans          from './modules/investment_plans'
import ipos                     from './modules/ipos'
import limitedPartners          from './modules/limited_partners'
import listings                 from './modules/listings'
import meetingNotes             from './modules/meeting_notes'
import schedules                from './modules/schedules'
import users                    from './modules/users'
import financeInformations      from './modules/finance_informations'
import codeLists                from './modules/code_lists'
import portfolios               from './modules/portfolios'
import piPortfolios             from './modules/pi_portfolios'
import ledgerCodes              from './modules/ledger_codes'
import properSettings           from './modules/proper_settings'

Vue.use(Vuex)

const state = () => ({
  isLoading: false,
  isSearching: false,
  isSidepanelFullWidth: false,
  showPopover: false,
  showModal: false,
  showNavMenu: false,
  showSearchEntity: false,
  showSidepanel: false,
  selectedSuggest: '',
  sidepanelContentComponent: '',
  sidepanelContentDirectory: '',
  modalSize: {
    width: 0,
    height: 0,
  }
})

const getters = {
  showOverlay: state => {
    return state.showNavMenu || state.showModal || state.showPopover || state.selectedSuggest
  },
  showMainPageLoadingIndicator: state => {
    return state.isLoading && !state.showSidepanel
  },
}

const mutations = {
  modalToggle (state) {
    state.showModal = !state.showModal
  },
  setModal (state, setModal) {
    state.showModal = setModal
  },
  setModalSize (state, modalSize) {
    state.modalSize.height = modalSize.height
    state.modalSize.width  = modalSize.width
  },
  searchControlToggle (state, isOpen) {
    state.isSearching = isOpen
  },
  showModalToggle (state, isOpen) {
    state.showModal = isOpen
  },
  showNavMenuToggle (state, isOepn) {
    state.showNavMenu = isOepn
  },
  setShowSidepanel (state, openSidepanel) {
    state.showSidepanel = openSidepanel
  },
  setIsLoading (state, isLoading) {
    state.isLoading = isLoading
  },
  setShowPopover (state, showPopover) {
    state.showPopover = showPopover
  },
  setSelectedSuggest (state, selectedSuggest) {
    state.selectedSuggest = selectedSuggest
  },
  setSidepanelContentComponent (state, componentName) {
    state.sidepanelContentComponent = componentName
  },
  setSidepanelContentDirectory (state, directory) {
    state.sidepanelContentDirectory = directory
  },
  setIsSidepanelFullWidth (state, bool) {
    state.isSidepanelFullWidth = bool
  },
}

const actions = {
  modalClose ({ commit }) {
    EventBus.$emit('modalClose')
    commit ('setModalSize', { height: 0, width: 0 })
    commit('showModalToggle', false)
  },
  modalOpen ({ commit }, modalSize) {
    let modalSizeUpdate = modalSize
    if (!modalSize) {
      modalSizeUpdate = {
        height: 0,
        width: 0
      }
    }
    commit ('setModalSize', modalSizeUpdate)
    commit('showModalToggle', true)
  },
  navMenuClose ({ commit }) {
    commit('showNavMenuToggle', false)
  },
  navMenuOpen ({ commit }) {
    commit('showNavMenuToggle', true)
  },
  searchClose ({ commit }) {
    commit('searchControlToggle', false)
  },
  searchOpen ({ commit }) {
    commit('searchControlToggle', true)
  },
  resetSidepanelContentComponent ({ commit }) {
    commit('setSidepanelContentComponent', '')
  },
  resetSidepanelContentDirectory ({ commit }) {
    commit('setSidepanelContentDirectory', '')
  },
  sidepanelClose ({ commit, dispatch }) {
    dispatch('resetSidepanelContentComponent')
    dispatch('resetSidepanelContentDirectory')
    commit('setShowSidepanel', false)
    commit('setIsSidepanelFullWidth', false)
  },
  sidepanelOpen ({ commit, state }, params) {
    commit('setSidepanelContentComponent', params.componentName)
    commit('setSidepanelContentDirectory', params.directory)
    if (params.isFull) {
      commit('setIsSidepanelFullWidth', true)
    }
    if (!state.showSidepanel) {
      commit('setShowSidepanel', true)
    }

  },
  setSidepanelFullscreen ({ commit }) {
    commit('setIsSidepanelFullWidth', true)
  },
  setSidepanelHalf ({ commit }) {
    commit('setIsSidepanelFullWidth', false)
  },
  activateLoading ({ commit }) {
    commit('setIsLoading', true)
  },
  deactiveLoading ({ commit }) {
    commit('setIsLoading', false)
  },
}

export default new Vuex.Store({
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    changeLogs,
    companySettings,
    contacts,
    corporateAnnouncements,
    customFields,
    dashboardViews,
    entities,
    investmentEvents,
    legalFunds,
    investments,
    investmentPlans,
    limitedPartners,
    listings,
    meetingNotes,
    users,
    ipos,
    funds,
    brokers,
    brokerAccounts,
    dailyPerformances,
    folders,
    approvals,
    schedules,
    financeInformations,
    codeLists,
    portfolios,
    piPortfolios,
    ledgerCodes,
    properSettings,
  },
  strict: 'true'
})
