import Vue       from 'vue'
import Vuex      from 'vuex'
import brokerAccountsApi  from '@/api/v1/broker_accounts'

import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'

Vue.use(Vuex)

const emptyBrokerAccounts = [
  {
    id: 0,
    broker_name: '',
    account_type: '집합',
    broker_account: '',
    note: '',
  },
  {
    id: 0,
    broker_name: '',
    account_type: '벤처',
    broker_account: '',
    note: '',
  },
  {
    id: 0,
    broker_name: '',
    account_type: '하이일드',
    broker_account: '',
    note: '',
  },
  {
    id: 0,
    broker_name: '',
    account_type: '고유',
    broker_account: '',
    note: '',
  }
]

const state = () => ({
  brokerAccounts: [],
  drilldownBroker: {},
  drilldownAggregateAccount: {},
  drilldownVentureAccount: {},
  drilldownHighYieldAccount: {},
  drilldownSelfAccount: {},
  emptyBrokerAccounts: cloneDeep(emptyBrokerAccounts),
})

const getters = {
  getField,
}

const actions = {
  createUpdateBrokerAccounts ({ commit, dispatch }, brokerAccounts) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      brokerAccountsApi.postBrokerAccounts(brokerAccounts).then(resp => {
        commit('setDrilldownBrokerAccounts', resp)
        commit('addDrilldownBrokerAccountsToBrokerAccounts')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getBrokerAccounts ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      brokerAccountsApi.getBrokerAccounts().then(resp => {
        commit('setBrokerAccounts', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  brokerAccountDetailsOpen ({ commit, dispatch }, brokerAccounts) {
    commit('setDrilldownBrokerAccounts', brokerAccounts)
    dispatch('sidepanelOpen', { componentName: 'BrokerAccountDetails', directory: 'views/ipo' }, { root : true })
  },
}

const mutations = {
  addDrilldownBrokerAccountsToBrokerAccounts (state) {
    let aggAccountIndex = state.brokerAccounts.findIndex((act) => act.id === state.drilldownAggregateAccount.id)
    if (aggAccountIndex < 0) {
      state.brokerAccounts.push(state.drilldownAggregateAccount)
    } else {
      state.brokerAccounts.slice(aggAccountIndex, 1, state.drilldownAggregateAccount)
    }
    let ventureAccountIndex = state.brokerAccounts.findIndex((act) => act.id === state.drilldownVentureAccount.id)
    if (ventureAccountIndex < 0) {
      state.brokerAccounts.push(state.drilldownVentureAccount)
    } else {
      state.brokerAccounts.slice(ventureAccountIndex, 1, state.drilldownVentureAccount)
    }
    let highYieldAccountIndex = state.brokerAccounts.findIndex((act) => act.id === state.drilldownHighYieldAccount.id)
    if (highYieldAccountIndex < 0) {
      state.brokerAccounts.push(state.drilldownHighYieldAccount)
    } else {
      state.brokerAccounts.slice(highYieldAccountIndex, 1, state.drilldownHighYieldAccount)
    }
    let selfAccountIndex = state.brokerAccounts.findIndex((act) => act.id === state.drilldownSelfAccount.id)
    if (selfAccountIndex < 0) {
      state.brokerAccounts.push(state.drilldownSelfAccount)
    } else {
      state.brokerAccounts.slice(selfAccountIndex, 1, state.drilldownSelfAccount)
    }
  },
  setBrokerAccounts (state, fromApi) {
    state.brokerAccounts = fromApi
  },
  setDrilldownBroker (state, broker) {
    state.drilldownBroker = broker
  },
  setDrilldownBrokerAccounts (state, brokerAccounts) {
    state.drilldownAggregateAccount = brokerAccounts.find(act => act.account_type === '집합')
    state.drilldownVentureAccount = brokerAccounts.find(act => act.account_type === '벤처')
    state.drilldownHighYieldAccount = brokerAccounts.find(act => act.account_type === '하이일드')
    state.drilldownSelfAccount = brokerAccounts.find(act => act.account_type === '고유')
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
