import base from './base'

const getProperSettings = (filter) => base
  .get('/proper_settings', {params: filter}).then(resp => resp.data)

const patchProperSetting = (portfolioSetting) => base
  .patch(`/proper_settings/${portfolioSetting.share_code}`, portfolioSetting).then(resp => resp.data)

export default {
  getProperSettings: getProperSettings,
  patchProperSetting: patchProperSetting,
}
