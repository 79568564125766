import base from './base'

const getCorporateAnnouncements = (data) => base
  .get('/announcements/', { params: data }).then(resp => resp.data)

const getCorporateAnnouncement = (corporateAnnouncementId) => base
  .get(`/announcements/${corporateAnnouncementId}`).then(resp => resp.data)

const putCorporateAnnouncements = (filterdData) => base
  .patch(`/announcements/0`, {user_announcements: filterdData} ).then(resp => resp.data)

export default {
  getCorporateAnnouncements: getCorporateAnnouncements,
  getCorporateAnnouncement: getCorporateAnnouncement,
  putCorporateAnnouncements: putCorporateAnnouncements,
}
