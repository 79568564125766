import base from './base'

const getMeetingNotes = (investmentId) => base
  .get(`/meeting_notes/?investment_id=${investmentId}`).then(resp => resp.data)

const getMeetingNote = (meetingNoteId) => base
  .get(`/meeting_notes/${meetingNoteId}`).then(resp => resp.data)

const searchMeetingNotes = (searchString) => base
  .get(`/meeting_notes/search_by_text/?search_text=${searchString}`).then(resp => resp.data)

const postMeetingNote = (meetingNoteParams, contentType='application/json') => base
.post('/meeting_notes/', meetingNoteParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateMeetingNote = (meetingNoteId, meetingNoteParams, contentType='application/json') => base
.patch(`/meeting_notes/${meetingNoteId}`, meetingNoteParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteMeetingNote = (meetingNoteId) => base
.delete(`/meeting_notes/${meetingNoteId}`).then(resp => resp.data)

export default {
  deleteMeetingNote: deleteMeetingNote,
  getMeetingNotes: getMeetingNotes,
  getMeetingNote: getMeetingNote,
  postMeetingNote: postMeetingNote,
  updateMeetingNote: updateMeetingNote,
  searchMeetingNotes: searchMeetingNotes,
}
