import base from './base'

const getFunds = () => base
  .get('/funds/').then(resp => resp.data)

const postFund = (fundParams) => base
  .post('/funds/', fundParams).then(resp => resp.data)

const putFund = (fundParams) => base
  .put(`/funds/${fundParams.id}`, fundParams).then(resp => resp.data)

const deleteFund = (fundId) => base
  .delete(`/funds/${fundId}`)

const getIpoFundLists = (ipoId) => base
  .get(`/funds/ipo_fund_lists/?ipo_id=${ipoId}`).then(resp => resp.data)

const updateIpoFundLists = (params, contentType='application/json') => base
  .patch('/funds/update_ipo_fund_lists/', params, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const copyIpoFundLists = (params, contentType='application/json') => base
  .patch('/funds/copy_ipo_fund_lists/', params, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

export default {
  getFunds: getFunds,
  postFund: postFund,
  putFund: putFund,
  deleteFund: deleteFund,
  getIpoFundLists: getIpoFundLists,
  updateIpoFundLists: updateIpoFundLists,
  copyIpoFundLists: copyIpoFundLists,
}
