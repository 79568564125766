import base from './base'

const getKsic = (filters) => base
  .get('/ksic/', { params: filters }).then(resp => resp.data)

const updateKsic = (ksic) => base
  .patch(`/ksic/${ksic.code}`, { ksic: ksic }).then(resp => resp.data)

const createKsic = (ksic) => base
  .post('/ksic/', { ksic: ksic }).then(resp => resp.data)

const deleteKsic = (ksic) => base
  .delete(`/ksic/${ ksic.code }`).then(resp => resp.data)

export default {
  getKsic: getKsic,
  createKsic: createKsic,
  updateKsic: updateKsic,
  deleteKsic: deleteKsic,
}
