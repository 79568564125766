import Vue from 'vue'
import Vuex from 'vuex'
import usersApi from '@/api/v1/users'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  currentUserInfo: {
    email: '',
    smsNumber: '',
    roles: '',
    userId: 0,
    companyId: 0,
    userName: '',
    department: '',
    team: '',
    title: '',
    layer: '',
  },
  drilldownUser: {
    id: 0,
    name: '',
    email: '',
    sms_number: '',
    roles: [],
    organogram: { department: '', team: '', title: '', layer: '' },
  },
  drilldownUserGroup: {
    id: 0,
    group_no: '',
    group_name: '',
    apply_to_investments: false,
    user_ids: [],
    user_names: '',
    dashboard_view_ids: [],
  },
  showUserGroupDetails: false,
  users: [],
  userGroups: [],
  roles: [
    {name: '전체', role: ''},
    {name: '변호사', role: 'lawyer'},
    {name: '비서', role: 'secretary'}
  ]
})

const getters = {
  getUserNameFromId: state => id => {
    let found = state.users.find(user => user.id === id)
    return (found) ? found.name : ''
  },
  usersByRole: state => whichRole => {
    return state.users.filter(user => user.roles.includes(whichRole))
  },
  userNamesFromIds: state => userIds => {
    let found = ''
    if (userIds) {
      found = state.users.filter(user => userIds.includes(user.id)).map(user => user.name).join(',')
    }
    return found
  },
  filteredUsersByIds: state => userIds => {
    return state.users.filter(user => userIds.includes(user.id))
  },
  currentUserId: state => {
    return state.currentUserInfo.userId
  },
  currentUserName: state => {
    return state.currentUserInfo.userName
  },
  currentIpoDetailsName: state => {
    return `company_${state.currentUserInfo.companyId}`
  },
  getField,
}

const actions = {
  createUserGroup ({ commit, dispatch }, group) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      usersApi.postUserGroup(group).then(resp => {
        commit('setDrilldownUserGroup', resp)
        commit('addDrilldownUserGroupToUserGroups')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  deleteUser ({commit}, userID) {
    return usersApi.deleteUser(userID).then(() =>{
      commit('removeUser', userID)
    })
  },
  getUsers ({ commit }) {
    return new Promise((resolve) => {
      usersApi.getUsers().then(resp => {
        commit('setUsers', resp)
        resolve()
      })
    })
  },
  getUsersByIds (context, userIds) {
    return new Promise(resolve => {
      usersApi.getUsersByIds(userIds).then(resp => {
        resolve(resp)
      })
    })
  },
  getUserGroups ({ commit }) {
    return new Promise((resolve) => {
      usersApi.getUserGroups().then(resp => {
        commit('setUserGroups', resp)
        resolve()
      })
    })
  },
  getCurrentUserInfo ({ commit }) {
    return usersApi.getCurrentUserInfo().then(resp => {
      localStorage.setItem('roles', resp.roles.toString())
      localStorage.setItem('userId', resp.id)
      localStorage.setItem('companyId', resp.company_id)
      localStorage.setItem('email', resp.email)
      localStorage.setItem('smsNumber', resp.sms_number)
      localStorage.setItem('userName', resp.name)
      localStorage.setItem('department', resp.organogram.department)
      localStorage.setItem('team', resp.organogram.team)
      localStorage.setItem('title', resp.organogram.title)
      localStorage.setItem('layer', resp.organogram.layer)
      commit('updateCurrentUserFromLocalStorage')
    })
  },
  getCurrentUserInfoOnMobile ({ commit }) {
    return usersApi.getCurrentUserInfo().then(resp => {
      commit('updateCurrentUserOnMobile', resp)
    })
  },
  updateUser ({ commit, dispatch }, user) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      usersApi.putUser(user).then(resp => {
        commit('setDrilldownUser', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateUserGroup ({ commit, dispatch }, group) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      usersApi.putUserGroup(group).then(resp => {
        commit('setDrilldownUserGroup', resp)
        commit('addDrilldownUserGroupToUserGroups')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  userDetailsOpen ({ commit, dispatch }, user) {
    commit('setDrilldownUser', user)
    dispatch('sidepanelOpen', { componentName: 'UserDetails', directory: 'views/manage_access' }, { root : true })
  },
  userGroupDetailsOpen ({ commit, dispatch }, group) {
    commit('setDrilldownUserGroup', group)
    dispatch('sidepanelOpen', { componentName: 'UserGroupDetails', directory: 'views/manage_access' }, { root : true })
  },
  searchUsersByText (context, filterParams) {
    return new Promise(resolve => {
      usersApi.searchUsers(filterParams).then(resp => {
        resolve(resp)
      })
    })
  },
}

const mutations = {
  addDrilldownUserGroupToUserGroups (state) {
    let index = state.userGroups.findIndex((group) => group.id === state.drilldownUserGroup.id)
    if (index < 0) {
      state.userGroups.push(state.drilldownUserGroup)
    } else {
      state.userGroups.splice(index, 1, state.drilldownUserGroup)
    }
  },
  removeUser (state, userID) {
    const index = state.users.findIndex(user => user.id === userID)
    if (index >= 0) {
      state.users.splice(index, 1)
    }
  },
  setDrilldownUser (state, user) {
    state.drilldownUser = user
  },
  setDrilldownUserGroup (state, group) {
    state.drilldownUserGroup = group
  },
  setUsers (state, fromApi) {
    state.users = fromApi
  },
  setUserGroups (state, fromApi) {
    state.userGroups = fromApi
  },
  updateCurrentUserFromLocalStorage (state) {
    state.currentUserInfo.roles      = localStorage.getItem('roles')
    state.currentUserInfo.userId     = parseInt(localStorage.getItem('userId'))
    state.currentUserInfo.companyId  = parseInt(localStorage.getItem('companyId'))
    state.currentUserInfo.email      = localStorage.getItem('email')
    state.currentUserInfo.smsNumber  = localStorage.getItem('smsNumber')
    state.currentUserInfo.userName   = localStorage.getItem('userName')
    state.currentUserInfo.department = localStorage.getItem('department')
    state.currentUserInfo.team       = localStorage.getItem('team')
    state.currentUserInfo.title      = localStorage.getItem('title')
    state.currentUserInfo.layer      = localStorage.getItem('layer')
  },
  updateCurrentUserOnMobile (state, payload) {
    state.currentUserInfo.roles      = payload['roles']
    state.currentUserInfo.userId     = parseInt(payload['id'])
    state.currentUserInfo.companyId  = parseInt(payload['company_id'])
    state.currentUserInfo.email      = payload['email']
    state.currentUserInfo.smsNumber  = payload['sms_number']
    state.currentUserInfo.userName   = payload['name']
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
