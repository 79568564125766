import Vue       from 'vue'
import Vuex      from 'vuex'
import PortfoliosApi   from '@/api/v1/portfolios'
import LpPortfoliosApi from '@/api/v1/lp_portfolios'

import cloneDeep from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'


Vue.use(Vuex)


const newPortfolio = {
  entity_name : '',
  entity_id : 0,
  share_code: '',
  name: '',
  base_asset_name: '',
  start_date: '',
  end_date: '',
  existence_term: '',
  organization_value: 0,
  contract_value: 0,
  gp: '',
  lp_business: '',
  lp_price: '',
  lp_value: '',
  lp_principal: '',
  major_condition: '',
  unrealized_gain: 0,
  collection_status: '',
  apply_date: '',
}

const emptyLpContact = {
  id: 0,
  share_code: '',
  work_type: '',
  role: '',
  corporate_name: '',
  contact_name: '',
  office_phone_number: '',
  mobile_phone_number: '',
  email: '',
  address: '',
  account_number: '',
  password: '',
  account_owner: '',
  note: '',
}

const state = () => ({
  portfolios: [],
  reportDetails: [],
  drilldownPortfolio: cloneDeep (newPortfolio),
  detailReportType: '',
  lpContacts: [],
})

const getters = {
  portfolioEntityName: state => {
    return state.drilldownPortfolio.entity_name ? state.drilldownPortfolio.entity_name : ''
  },
  lpPortfolioFundForm: state => {
    return state.drilldownPortfolio.fund_form ? state.drilldownPortfolio.fund_form : ''
  },
  portfolioName: state => {
    return state.drilldownPortfolio.name ? state.drilldownPortfolio.name : ''
  },
  getField,
}

const actions = {
  getPortfolios ({ commit, dispatch }, filter) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      PortfoliosApi.getPortfolios(filter).then(resp => {
        commit('setPortfolios', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getGroupPortfolios ({ commit, dispatch }, filter) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      PortfoliosApi.getGroupPortfolios(filter).then(resp => {
        commit('setPortfolios', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getLpDetailPortfolios ({ commit, dispatch }, filter) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      LpPortfoliosApi.getLpDetailPortfolios(filter).then(resp => {
        commit('setPortfolios', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  portfolioDetailsOpen ({ commit, dispatch }, portfolio) {
    commit('setDrilldownPortfolio', portfolio)
    dispatch('sidepanelOpen', { componentName: 'PortfolioDetails', directory: 'views/portfolio' }, { root : true })
  },
  portfolioDetailsOpenMobile ({ commit, dispatch }, portfolio) {
    commit('setDrilldownPortfolio', portfolio)
    dispatch('sidepanelOpen', { componentName: 'PortfolioDetailsMobile', directory: 'components/mobile' }, { root : true })
  },
  lpPortfolioDetailsOpen ({ commit, dispatch }, portfolio) {
    commit('setDrilldownPortfolio', portfolio)
    dispatch('sidepanelOpen', { componentName: 'LpPortfolioDetails', directory: 'views/portfolio' }, { root : true })
  },
  portfolioSetEntityComment ({ commit }, portfolioParam) {
    commit('setPortfolioEntityComment', portfolioParam)
  },
  portfolioSetUnderwriter ({ commit }, portfolioParam) {
    commit('setPortfolioUnderwriter', portfolioParam)
  },
  portfolioSetCollectionStatus ({ commit }, portfolioParam) {
    commit('setPortfolioCollectionStatus', portfolioParam)
  },
  portfolioSetAssetType ({ commit }, portfolioParam) {
    commit('setPortfolioAssetType', portfolioParam)
  },
  sortPortfolios ({ commit }, sortKeys) {
    commit('setSortPortfolios', sortKeys)
  },
  getLpContacts ({ commit, dispatch }, filter) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      LpPortfoliosApi.getLpContacts(filter).then(resp => {
        commit('setLpContacts', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  patchLpContacts ({ state, commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let filteredLpContacts = state.lpContacts.filter(contact => contact.work_type === params.workType)
      LpPortfoliosApi.patchLpContacts({share_code: params.shareCode, contacts: filteredLpContacts}).then(resp => {
        commit('setLpContacts', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  }
}


const mutations = {
  setPortfolios (state, fromApi) {
    state.portfolios = fromApi
  },
  setReportDetails (state, fromApi) {
    state.reportDetails = fromApi
  },
  setdetailReportType (state, fromApi) {
    state.detailReportType = fromApi
  },
  setDrilldownPortfolio (state, portfolio) {
    state.drilldownPortfolio = portfolio
  },
  setPortfolioEntityComment (state, portfolioParam) {
    let foundIndex = state.portfolios.findIndex(portfolio => portfolio.entity_id === portfolioParam.entity_id)
    if (foundIndex >= 0) {
      state.portfolios[foundIndex].entity_comment = portfolioParam.entityComment
    }
  },
  setPortfolioUnderwriter (state, portfolioParam) {
    let foundIndex = state.portfolios.findIndex(portfolio => portfolio.entity_id === portfolioParam.entity_id)
    if (foundIndex >= 0) {
      state.portfolios[foundIndex].underwriter = portfolioParam.underwriter
    }
  },
  setPortfolioCollectionStatus (state, portfolioParam) {
    let foundIndex = state.portfolios.findIndex(portfolio => portfolio.entity_id === portfolioParam.entity_id)
    if (foundIndex >= 0) {
      state.portfolios[foundIndex].collection_status = portfolioParam.collectionStatus
    }
  },
  setPortfolioAssetType (state, portfolioParam) {
    let foundIndex = state.portfolios.findIndex(portfolio => portfolio.entity_id === portfolioParam.entity_id)
    if (foundIndex >= 0) {
      state.portfolios[foundIndex].asset_type = portfolioParam.assetType
    }
  },
  setSortPortfolios (state, sortKeys) {
    let key = sortKeys.key
    let dir = sortKeys.dir
    state.portfolios.sort((aTarget, bTarget) => {
      let aValue, bValue
      if (typeof aTarget === 'string') {
        aValue = aTarget[key].toUpperCase()
        bValue = bTarget[key].toUpperCase()
      } else {
        aValue = aTarget[key]
        bValue = bTarget[key]
      }

      if (dir === 'asc') {
        if (aValue < bValue) {
          return -1
        } else if (aValue > bValue) {
          return 1
        } else {
          return 0
        }
      } else {
        if (aValue < bValue) {
          return 1
        } else if (aValue > bValue) {
          return -1
        } else {
          return 0
        }
      }
    })
  },
  setLpContacts (state, fromApi) {
    state.lpContacts = fromApi
  },
  addNewLpContact (state, filter) {
    let newLpContact = cloneDeep(emptyLpContact)
    newLpContact.share_code = filter.shareCode
    newLpContact.work_type = filter.workType
    state.lpContacts.push(newLpContact)
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
