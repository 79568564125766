import base from './base'

const getEntities = (filters={}) => base
  .get('/entities/', { params: filters }).then(resp => resp.data)

const searchEntities = (searchText) => base
  .get(`/entities/search_by_name?entity_name=${searchText}`).then(resp => resp.data)

const getEntity = (entityId) => base
  .get(`/entities/${entityId}`).then(resp => resp.data)

const getBusinessLicenseNumberValid = (licenseNumber) => base
  .get(`/entities/business_license_valid?business_license_number=${licenseNumber}`).then(resp => resp.data)

const postEntity = (entityParams, contentType='application/json') => base
.post('/entities/', entityParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateEntity = (entityId, entityParams, contentType='application/json') => base
.patch(`/entities/${entityId}`, entityParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteEntity = (entityId) => base
.delete(`/entities/${entityId}`).then(resp => resp.data)

const uploadEntity = (entityParams, contentType='multipart/form-data') => base
.post('/entities/attached_file/', entityParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateEntityComment = (entityValues) => base
  .post('/entities/update_comment', entityValues).then(resp => resp.data)

export default {
  deleteEntity: deleteEntity,
  getEntities: getEntities,
  getBusinessLicenseNumberValid: getBusinessLicenseNumberValid,
  getEntity: getEntity,
  postEntity: postEntity,
  searchEntities: searchEntities,
  updateEntity: updateEntity,
  uploadEntity: uploadEntity,
  updateEntityComment: updateEntityComment,
}
