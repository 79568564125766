import base from './base'

const getBrokerAccounts = () => base
  .get('/broker_accounts/').then(resp => resp.data)

const postBrokerAccounts = (brokerAccountParams) => base
  .post('/broker_accounts/', brokerAccountParams).then(resp => resp.data)

export default {
  getBrokerAccounts: getBrokerAccounts,
  postBrokerAccounts: postBrokerAccounts,
}
