import base from './base'

const getFolders = () => base
  .get('/folders/').then(resp => resp.data)

const getFolderLists = () => base
  .get('/folders/folder_lists/').then(resp => resp.data)

const getInvestmentFolder = (id) => base
  .get(`/folders/${id}`).then(resp => resp.data)

const createFolder = (parent_folder_id, name) => base
  .post('/folders/', { parent_folder_id: parent_folder_id, name: name })
  .then(response => response.data)

const deleteFolder = (folder_id) => base
  .delete(`/folders/${folder_id}`)
  .then(response => response.data)

const renameFolder = (folder_id, name) => base
  .put(`/folders/${folder_id}`, {name: name})
  .then(response => response.data)

const moveFolder = (folder_id, parent_folder_id) => base
  .post('/folders/move_folder/', { folder_id: folder_id, parent_folder_id: parent_folder_id })
  .then(response => response.data)

const moveFile = (folder_id, custom_field_value_id) => base
  .post('/folders/move_file/', { folder_id: folder_id, custom_field_value_id: custom_field_value_id })
  .then(response => response.data)

export default {
  getFolders: getFolders,
  getFolderLists: getFolderLists,
  getInvestmentFolder: getInvestmentFolder,
  createFolder: createFolder,
  deleteFolder: deleteFolder,
  renameFolder: renameFolder,
  moveFolder: moveFolder,
  moveFile: moveFile,
}
