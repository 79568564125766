import base from './base'

const getCompanySettings = () => base
  .get('/reach_company_settings/').then(resp => resp.data)

const postCompanySettings = (params) => base
  .post('/reach_company_settings/', params).then(resp => resp.data)

export default {
  getCompanySettings: getCompanySettings,
  postCompanySettings: postCompanySettings,
}
