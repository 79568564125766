import Vue             from 'vue'
import Vuex            from 'vuex'
import router          from '@/router'
import investmentsApi  from '@/api/v1/investments'
import customFieldsApi from '@/api/v1/custom_fields'
import contactsApi     from '@/api/v1/contacts'
import approvalsApi    from '@/api/v1/approvals'
import EventBus        from '@/utils/event-bus'
import DefaultObjects  from '@/utils/default-objects'
import cloneDeep       from 'lodash/cloneDeep'
import groupBy         from 'lodash/groupBy'
import uniqBy          from 'lodash/uniqBy'
import union           from 'lodash/union'
import dayjs           from 'dayjs'
import { MessageBox }  from 'element-ui'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

// Private stuff to be used within module
function showField (field, arrayOfFields) {
  if (field.rules.all === undefined || field.rules.all.length === 0) {
    return true
  } else {
    return field.rules.all.every(rule => {
      return ruleApplies(rule, arrayOfFields)
    })
  }
}

function ruleApplies (rule, arrayOfFields) {
  let parentField = arrayOfFields.find(field => field.id === rule.custom_field_template_id)
  switch (rule.operator) {
    case '=':
      if (parentField === undefined) {
        return false
      }
      return parentField.value === rule.custom_field_value
    case '≠':
      if (parentField === undefined) {
        return true
      }
      return parentField.value !== rule.custom_field_value
    case 'includes':
      if (parentField === undefined || parentField.value === null) {
        return false
      }
      return parentField.value &&
        (
          parentField.value.includes(rule.custom_field_value) ||
          (rule.custom_field_values && rule.custom_field_values.includes(parentField.value)) ||
          (rule.custom_field_values && parentField.value_json.some(value => rule.custom_field_values.includes(value)))
        )
    case '>':
      if (parentField === undefined) {
        return false
      }
      return parentField.value > rule.custom_field_value
    case '≥':
      if (parentField === undefined) {
        return false
      }
      return parentField.value >= rule.custom_field_value
    case '<':
      if (parentField === undefined) {
        return false
      }
      return parentField.value < rule.custom_field_value
    case '≤':
      if (parentField === undefined) {
        return false
      }
      return parentField.value <= rule.custom_field_value
    case 'before':
      if (parentField === undefined) {
        return false
      }
      return parentField.value && dayjs(parentField.value).isBefore(rule.custom_field_value, 'day')
    case 'after':
      if (parentField === undefined) {
        return false
      }
      return parentField.value && dayjs(parentField.value).isAfter(rule.custom_field_value, 'day')
    default:
      return false
  }
}
///////////////////// end of Private Stuffs


const state = () => ({
  drilldownInvestment: DefaultObjects.newEmptyInvestment(),
  drilldownInvestmentCustomFields: [],
  drilldownIsViewMode: true,
  drilldownInvestmentContacts: [],
  drilldownInvestmentDetails: [],
  drilldownEditedFields: [],
  drilldownApprovalRequests: [],
  drilldownApprovalResponses: [],
  investments: [],
  investmentsSearch: [],
  investmentReviewBoardReport: {
    reach_report_infos: [],
    reports: {}
  },
  investmentReportMinutes: {
    reach_report_infos: [],
    reports: {}
  },
  searchResults: [],
  drilldownApprovalRequestCount: 0,
  drilldownCanViewBilling: false,
  fundFormOptions: ['프로젝트', '블라인드', '조합'],
})

const getters = {
  drilldownInvestmentId: state => {
    return (state.drilldownInvestment.id > 0) ? state.drilldownInvestment.id : 0
  },
  drilldownInvestmentName: state => {
    return (state.drilldownInvestment) ? state.drilldownInvestment.investment_name : ''
  },
  drilldownInvestmentMsDriveUrls: state => {
    return (state.drilldownInvestment) ? state.drilldownInvestment.ms_drive_urls : ''
  },
  drilldownInvestmentContactsUpdated: state => {
    return state.drilldownInvestmentContacts.filter(contact => contact.updated_on_client)
  },
  hasdrilldownEditedFields: state => {
    return state.drilldownEditedFields.length > 0
  },
  isNewInvestment: state => {
    return state.drilldownInvestment.id === 0
  },
  isInvestmentReadyToMove: state => {
    return !state.drilldownInvestment.incomplete_required_fields ||
           !state.drilldownInvestmentCustomFields.some(field => field.required && !field.value)
  },
  currentDashboardViewId: (state, getters, rootState) => {
    return rootState.dashboardViews.currentDashboardView.id ? rootState.dashboardViews.currentDashboardView.id : 0
  },
  dashboardMappingFields: (state, getters, rootState) => {
    if (getters.isNewInvestment) {
      return state.drilldownInvestmentCustomFields
    } else {
      return state.drilldownInvestmentCustomFields.filter(template => rootState.dashboardViews.currentDashboardView.mapping_field_template_ids.includes(template.id))
    }
  },
  requiredEmptyFields: (state, getters) => {
    let fields = getters.customFields.filter((field) => {
      if (!field.required || field.value) {
        return false
      }
      let editedField = state.drilldownEditedFields.find(ef => ef.id === field.id)
      return !editedField || (editedField.required && !editedField.value)
    })
    return fields
  },
  customFields: (state, getters, rootState) => {
    let fields = (rootState.isSearching) ? state.drilldownInvestmentCustomFields : getters.sidepanelShowFields
    return fields
  },
  sidepanelShowFields: (state, getters) => {
    return getters.dashboardMappingFields.filter(field => showField(field, getters.dashboardMappingFields)).sort((a, b) => a.sort_index - b.sort_index)
  },
  sidepanelShowFieldsByTemplateId: (state, getters) => {
    return groupBy(getters.sidepanelShowFields, 'id')
  },
  drilldownInvestmentFiles: (state, getters) => {
    return uniqBy(getters.sidepanelShowFields.filter(field => field.field_type == 'file').sort((a,b) => a.sort_index - b.sort_index), 'id')
  },
  drilldownPiInvestmentFiles: (getters) => {
    // return uniqBy(getters.dashboardMappingFields.filter(field => field.field_type == 'file').sort((a,b) => a.sort_index - b.sort_index), 'id')
    return getters.drilldownInvestmentCustomFields.filter(field => field.field_type === 'file')
  },
  hasDrilldownInvestmentFiles: (state, getters) => {
    return getters.drilldownInvestmentFiles.length > 0
  },
  getInvestmentsSearchById: state => id => {
    let found = state.investmentsSearch.find(investment => investment.id === id)
    return (found) ? found : null
  },
  schedulesOnInvestments: state => {
    var allSchedules = []
    state.investments.forEach(investment => {
      allSchedules = union(allSchedules, investment.schedules)
    })
    return allSchedules
  },
  getField,
}

const actions = {
  createInvestment ({ commit, dispatch, getters }, investment) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.postInvestment(investment, getters.currentDashboardViewId).then(resp => {
        // custom_field-stage를 grouping 한 상태에서 card의 custom_field-stage를 생성/변경 했을때 refresh 없이 이동이 되도록 하기위해서 custom_fields를 add
        commit('setDrilldownInvestment', resp) // needed for custom fields on create
        dispatch('deactiveLoading', null, { root : true })
        dispatch('getInvestmentCard', resp.id)
        resolve(resp)
      })
    })
  },
  createMsFolder ({ getters, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.createMsFolder(getters.drilldownInvestmentId).then(() => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateInvestmentStatus ({ commit, dispatch }, {investmentId: investmentId, investmentStatus: investmentStatus}) {
    console.log('invesmtent status is: ', investmentStatus)
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.updateInvestmentStatus(investmentId, investmentStatus).then(resp => {
        commit('setDrilldownInvestment', resp) // probably need to update the tab list as well
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  deleteInvestment ({ commit, dispatch }, investmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.deleteInvestment(investmentId).then(() => {
        commit('resetDrilldownInvestment')
        commit('removeFromInvestments', investmentId)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getInvestments ({ commit, dispatch, getters }, filterParams) {
    let getInvestmentParams = cloneDeep(filterParams)
    getInvestmentParams['dashboard_view_id'] = getters.currentDashboardViewId
    commit('setInvestments', [])
    dispatch('activateLoading', null, { root : true })
    investmentsApi.getInvestments(getInvestmentParams).then(resp => {
      commit('setInvestments', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getFilteredInvestments ({ commit, dispatch, getters }, filterParams) {
    let getInvestmentParams = cloneDeep(filterParams)
    getInvestmentParams['dashboard_view_id'] = getters.currentDashboardViewId
    commit('setInvestments', [])
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.getFilteredInvestments(getInvestmentParams).then(resp => {
        commit('setInvestments', resp)
        dispatch('deactiveLoading', null, { root : true })
        EventBus.$emit('updated-investment-list')
        resolve()
      })
    })
  },
  getInvestmentsSearch ({ commit, dispatch }) {
    dispatch('activateLoading', null, { root : true })
    investmentsApi.getInvestmentsSearch().then(resp => {
      commit('setInvestmentsSearch', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getInvestmentDrilldownOverview ({ getters, commit }) {
    return new Promise(resolve => {
      investmentsApi.getInvestmentDrilldownOverview(getters.drilldownInvestmentId).then(resp => {
        commit('setInvestmentCanViewBilling', resp.can_view_billing)
        commit('setInvestmentApprovalRequestCount', resp.request_count)
        resolve(resp)
      })
    })
  },
  getMyInvestmentsSearch ({ commit, dispatch }) {
    dispatch('activateLoading', null, { root : true })
    investmentsApi.getMyInvestmentsSearch().then(resp => {
      commit('setInvestmentsSearch', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getInvestmentCard ({ commit, dispatch, getters }, investmentId) {
    dispatch('activateLoading', null, { root : true })
    return investmentsApi.getInvestmentCard(investmentId, getters.currentDashboardViewId).then(resp => {
      commit('updateInvestmentInInvestments', resp)
      dispatch('deactiveLoading', null, { root : true })
      return resp
    })
  },
  getInvestmentData ({ commit, dispatch, getters }, investmentId) {
    dispatch('activateLoading', null, { root : true })
    return investmentsApi.getInvestmentInfo(investmentId, getters.currentDashboardViewId).then(resp => {
      commit('setDrilldownInvestment', resp)
      dispatch('deactiveLoading', null, { root : true })
      dispatch('getInvestmentDetails', investmentId)
      return resp
    })
  },
  getInvestmentDetails ({ commit, dispatch }, investmentId) {
    dispatch('activateLoading', null, { root : true })
    investmentsApi.getInvestmentDetails(investmentId).then(resp => {
      commit('setDrilldownInvestmentDetails', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getContactsForInvestment ({ commit }, investmentId) {
    contactsApi.getContactsForInvestment(investmentId).then(resp => {
      commit('setDrilldownInvestmentContacts', resp)
    })
  },
  createApprovalRequest ({ commit, dispatch }, approvalRequest) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      approvalsApi.postApprovalRequest(approvalRequest).then(resp => {
        commit('addApprovalRequests', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteApprovalRequest ({ commit, dispatch }, approvalRequestId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      approvalsApi.deleteApprovalRequest(approvalRequestId).then(resp => {
        commit('removeApprovalRequest', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getApprovalRequestsForInvestment ({ commit, dispatch }, investmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      approvalsApi.getApprovalRequestsForInvestment(investmentId).then(resp => {
        commit('setDrilldownApprovalRequests', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getNeedMyApprovalResponseForInvestment ({ commit, dispatch }, investmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      approvalsApi.getNeedMyApprovalResponseForInvestment(investmentId).then(resp => {
        commit('setDrilldownApprovalResponses', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  addInvestmentContactLink ({ state, commit }, contactLink) {
    let newInvestmentContactLink = cloneDeep(contactLink)
    newInvestmentContactLink['investment_id'] = state.drilldownInvestment.id
    return new Promise((resolve,reject) => {
      contactsApi.postMappingInvestmentContact(newInvestmentContactLink).then(resp => {
        newInvestmentContactLink['mapping_investment_contact_id'] = resp.id
        newInvestmentContactLink['to_delete']                  = false
        newInvestmentContactLink['updated_on_client']          = false
        commit('addToDrilldownInvestmentContacts', newInvestmentContactLink)
        resolve()
      }).catch((err) => {
        return reject(err)
      })
    })
  },
  updateContactsForInvestment ({ getters, dispatch }) {
    dispatch('activateLoading', null, { root : true })
    return Promise.all(getters.drilldownInvestmentContactsUpdated.map(contact => {
      if (contact.to_delete) {
        return dispatch('removeInvestmentContactLink', contact)
      } else {
        return dispatch('updateMappingInvestmentContact', contact)
      }
    })).then(() => {
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  updateMappingInvestmentContact (context, contact) {
    let params = {
      is_client: contact.is_client,
      role: contact.role
    }
    return new Promise(resolve => {
      contactsApi.updateMappingInvestmentContact(contact.mapping_investment_contact_id, params).then(() => {
        resolve()
      })
    })
  },
  removeInvestmentContactLink ({ state, commit }, contact) {
    let params = {
      investment_id: state.drilldownInvestment.id,
      contact_id:    contact.contact_id
    }
    return new Promise(resolve => {
      contactsApi.deleteMappingInvestmentContact(params).then(() => {
        commit('removeFromDrilldownInvestmentContacts', contact.contact_id)
        resolve()
      })
    })
  },
  getInvestmentCustomFields ({ commit, state, dispatch, getters, rootState }, dashboardViewId = getters.currentDashboardViewId) {
    dispatch('activateLoading', null, { root : true })
    if (rootState['isSearching']) {
      dashboardViewId = 0 // get all fields
    }
    customFieldsApi.getCustomFieldsForInvestment(state.drilldownInvestment.id, dashboardViewId).then(resp => {
      commit('setCustomFields', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getInvestmentReportFields ({ commit, state, dispatch, getters }, reportType) {
    dispatch('activateLoading', null, { root : true })
    customFieldsApi.getCustomFieldsForInvestment(state.drilldownInvestment.id, getters.currentDashboardViewId, null, reportType).then(resp => {
      commit('setCustomFields', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getCustomFieldsForInvestmentByType ({ commit, state, dispatch, getters }, fieldType) {
    dispatch('activateLoading', null, { root : true })
    customFieldsApi.getCustomFieldsForInvestment(state.drilldownInvestment.id, getters.currentDashboardViewId, fieldType).then(resp => {
      commit('setCustomFields', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  openInvestmentDrilldown ({ commit, dispatch }, investmentId) {
    dispatch('activateLoading', null, { root : true })
    return investmentsApi.getInvestmentInfo(investmentId, localStorage.getItem('lastDashboardViewId')).then(resp => {
      commit('setDrilldownInvestment', resp)
      dispatch('sidepanelOpen', { componentName: 'InvestmentDrilldown', directory: 'views/dashboard' }, { root : true })
      dispatch('deactiveLoading', null, { root : true })
      dispatch('getInvestmentDetails', investmentId)
      return resp
    })
  },
  openNewInvestment ({ commit, dispatch }) {
    dispatch('activateLoading', null, { root : true })
    dispatch('investmentDetailsOpen', DefaultObjects.newEmptyInvestment())
    commit('setDrilldownIsViewMode', false)
  },
  checkEditedFields ({ getters, dispatch }) {
    return new Promise(resolve => {
      if (getters.hasdrilldownEditedFields) {
        MessageBox.confirm(`You have unsaved changes to ${getters.drilldownInvestmentName}?`, 'Warning', {
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          type: 'warning'
        }).then(() => {
          // confirm
          dispatch('updateAllCustomFieldValues', getters.drilldownInvestmentId).then(() => {
            dispatch('resetDrilldownInvestmentMetaData')
            resolve()
          })
        }).catch(() => {
          // cancel
          dispatch('resetDrilldownInvestmentMetaData')
          resolve()
        })
      } else {
        dispatch('resetDrilldownInvestmentMetaData')
        resolve()
      }
    })
  },
  investmentDetailsOpen ({ getters, dispatch }, investment) {
    if (getters.isNewInvestment || investment.id != getters.drilldownInvestmentId) {
      // do not clear drilldowncustomfields when investment same
      // as sidepanel wont reload
      dispatch('checkEditedFields').then(() => {
        dispatch('loadInvestmentDetails', investment)
      })
    }
  },
  loadInvestmentDetails ({ commit, getters, dispatch }, investment) {
    commit('setDrilldownInvestment', investment)
    if (!getters.isNewInvestment) {
      let newQueryParams = cloneDeep(router.history.current.query)
      if (investment &&
          (!newQueryParams['investment_id'] || parseInt(newQueryParams['investment_id']) !== investment.id)) {
        newQueryParams['investment_id'] = investment.id
        router.replace({ query: newQueryParams })
      }
    }
    dispatch('sidepanelOpen', { componentName: 'InvestmentDrilldown', directory: 'views/dashboard' }, { root : true })
  },
  resetEditing ({ commit }) {
    commit('resetDrilldownInvestment')
    commit('resetDrilldownInvestmentEditingFields')
    commit('resetInvestmentCustomFields')
    commit('setDrilldownIsViewMode', true)
  },
  resetDrilldownInvestmentMetaData ({ commit }) {
    commit('resetDrilldownInvestmentEditingFields')
    commit('resetInvestmentCustomFields')
    commit('setDrilldownIsViewMode', true)
  },
  resetInvestmentSearchResults ({ commit }) {
    commit('setSearchResults', [])
  },
  searchInvestments (context, searchText) { // for case search
    return new Promise(resolve => {
      investmentsApi.searchInvestments({search_text: searchText, search_at: 'ALL'}).then(resp => {
        resolve(resp)
      })
    })
  },
  investmentSearch ({ commit, dispatch }, searchParams) {
    dispatch('activateLoading', null, { root : true })
    investmentsApi.searchInvestments(searchParams).then(resp => {
      commit('setSearchResults', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  // entry point to update a single custom field value or file field value
  listUpdateAnyCustomFieldValue ({ dispatch }, field) {
    if (field.investment_id <= 0) {
      return
    }
    if (field.field_type === 'file') {
      return dispatch('listUpdateFileFieldValue', field)
    } else {
      return dispatch('listUpdateCustomFieldValue', field)
    }
  },
  listUpdateCustomFieldValue ({ commit, dispatch }, sourceField) {
    if (sourceField.investment_id <= 0) {
      return
    }
    let field = cloneDeep(sourceField)
    field.id = sourceField.custom_field_value_id
    field.custom_field_template_id = sourceField.custom_field_template_id || sourceField.id
    field.active = field.value !== null
    if (field.changed) {
      commit('resetCustomFieldChanged', sourceField)
    }
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      customFieldsApi.postCustomFieldValue(field).then(resp => {
        if (!sourceField.custom_field_value_id) { // created field replaced in list edit view
          sourceField.custom_field_value_id = resp.id
        }
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  listUpdateFileFieldValue ({ commit, dispatch }, field) {
    if (field.investment_id <= 0) {
      return
    }
    let data = new FormData()
    if (field.changed) {
      data.append('custom_field_value[id]', field.custom_field_value_id || '')
      data.append('custom_field_value[investment_id]', field.investment_id)
      data.append('custom_field_value[custom_field_template_id]', field.id)
      data.append('custom_field_value[custom_field_name]', field.custom_field_name)
      data.append('custom_field_value[field_type]', field.field_type)
      if (field.entity_id !== null && field.entity_id !== undefined) {
        data.append('custom_field_value[entity_id]', field.entity_id)
      }
      if (field.value_json) {
        data.append('custom_field_value[value_json]', JSON.stringify(field.value_json))
      }
      if (field.value === null) {
        data.append('custom_field_value[active]', false)
      } else if (field.field_type === 'file') {
        // only when file is uploaded
        if (field.value instanceof File) {
          data.append('custom_field_value[value]', field.value, field.value.name)
        }
        if (field.metadata) {
          data.append('custom_field_value[metadata][file_date]', field.metadata.file_date || '')
          data.append('custom_field_value[metadata][content_description]', field.metadata.content_description || '')
          data.append('custom_field_value[metadata][file_type]', field.metadata.file_type || '')
        }
      } else if (field.field_type === 'date') {
        // dont store invalid dates
        if (dayjs(field.value).isValid()) {
          data.append('custom_field_value[value]', dayjs(field.value).format('YYYY-MM-DD'))
        }
      } else if (field.value !== null && field.value !== undefined) {
        data.append('custom_field_value[value]', field.value)
      }
      commit('resetCustomFieldChanged', field)
    }
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      customFieldsApi.postFieldFieldValue(data).then(resp => {
        if (!field.custom_field_value_id) { // created field replaced in list edit view
          field.custom_field_value_id = resp.id
        }
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  listUpdateInvestmentData ({ commit, dispatch, getters }, investmentData) {
    return new Promise((resolve) => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.updateInvestment(investmentData.id, investmentData, getters.currentDashboardViewId).then(resp => {
        // custom_field-stage를 grouping 한 상태에서 card의 custom_field-stage를 생성/변경 했을때 refresh 없이 이동이 되도록 하기위해서 custom_fields를 add
        commit('setDrilldownInvestment', resp)
        dispatch('deactiveLoading', null, { root : true })
        dispatch('getInvestmentCard', resp.id)
        resolve()
      })
    })
  },
  // entry point for updating all custom field values for investment
  updateAllCustomFieldValues ({ commit, dispatch }, investmentId) {
    if (investmentId <= 0) {
      return Promise.resolve(null)
    }
    dispatch('activateLoading', null, { root : true })
    return Promise.all([dispatch('updateCustomFieldValues', investmentId), dispatch('updateFileFieldValues', investmentId)])
      .then(() => {
        commit('resetDrilldownInvestmentEditingFields')
        dispatch('getInvestmentCustomFields')
        dispatch('deactiveLoading', null, { root : true })
      })
  },
  updateCustomFieldValues ({ getters, state }, investmentId) {
    if (investmentId <= 0) {
      return Promise.resolve(null)
    }
    if (getters.hasdrilldownEditedFields) {
      let fields = state.drilldownEditedFields.filter(field => field.field_type !== 'file').map(field => {
        let copy = cloneDeep(field)
        copy.id = field.custom_field_value_id
        copy.investment_id = investmentId
        copy.custom_field_template_id = field.id
        copy.entity_id = state.drilldownInvestment.entity_id
        copy.active = !((field.value === null || field.value === '') && (field.field_type !== 'table' && field.value_json !== null))
        return copy
      })
      let customFields = fields.filter(field => field.field_type !== 'file')
      return new Promise(resolve => {
        customFieldsApi.updateCustomFieldValues({custom_field_values: customFields}).then(resp => {
          resolve(resp)
        })
      })
    } else {
      return Promise.resolve(null)
    }
  },
  updateFileFieldValues ({ getters, state }, investmentId) {
    if (investmentId <= 0) {
      return Promise.resolve(null)
    }
    if (getters.hasdrilldownEditedFields) {
      let data = new FormData()
      state.drilldownEditedFields.filter(field => field.field_type === 'file').forEach(field => {
        data.append('custom_field_values[][id]', field.custom_field_value_id || '')
        data.append('custom_field_values[][investment_id]', investmentId)
        data.append('custom_field_values[][custom_field_template_id]', field.id)
        data.append('custom_field_values[][custom_field_name]', field.custom_field_name)
        data.append('custom_field_values[][field_type]', field.field_type)
        data.append('custom_field_values[][entity_id]', state.drilldownInvestment.entity_id)
        if (field.value_json) {
          data.append('custom_field_values[][value_json]', JSON.stringify(field.value_json))
        }
        if (field.value === null || field.value === '') {
          if (field.field_type !== 'table' && field.value_json !== null) {
            data.append('custom_field_values[][active]', false)
          }
        } else {
          data.append('custom_field_values[][active]', true)
          if (field.field_type === 'file') {
            // only when file is uploaded
            if (field.value instanceof File) {
              data.append('custom_field_values[][value]', field.value, field.value.name)
            }
            if (field.metadata) {
              data.append('custom_field_values[][metadata][file_date]', field.metadata.file_date || '')
              data.append('custom_field_values[][metadata][content_description]', field.metadata.content_description || '')
              data.append('custom_field_values[][metadata][file_type]', field.metadata.file_type || '')
            }
          } else if (field.field_type === 'date') {
            if (dayjs(field.value).isValid()) {
              data.append('custom_field_values[][value]', dayjs(field.value).format('YYYY-MM-DD'))
            }
          } else {
            data.append('custom_field_values[][value]', field.value)
          }
        }
        if (field.folder_id > 0) {
          data.append('custom_field_values[][folder_id]', field.folder_id)
        }
      })
      if (!data.entries().next().done) {
        return new Promise(resolve => {
          customFieldsApi.updateFileFieldValues(data).then(resp => {
            resolve(resp)
          })
        })
      } else {
        return Promise.resolve(null)
      }
    }
  },
  updateInvestmentData ({ state, commit, dispatch, getters }, investmentData) {
    return new Promise((resolve) => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.updateInvestment(state.drilldownInvestment.id, investmentData, getters.currentDashboardViewId).then(resp => {
        // custom_field-stage를 grouping 한 상태에서 card의 custom_field-stage를 생성/변경 했을때 refresh 없이 이동이 되도록 하기위해서 custom_fields를 add
        commit('setDrilldownInvestment', resp)
        dispatch('deactiveLoading', null, { root : true })
        dispatch('getInvestmentCard', resp.id)
        resolve()
      })
    })
  },
  updateInvestmentDetails ({ state, commit, dispatch }, investmentId) {
    return new Promise((resolve) => {
      if (state.drilldownInvestmentDetails.length > 0) {
        dispatch('activateLoading', null, { root : true })
        investmentsApi.batchUpdateInvestmentDetails(investmentId, { investment_details: state.drilldownInvestmentDetails }).then(resp => {
          // custom_field-stage를 grouping 한 상태에서 card의 custom_field-stage를 생성/변경 했을때 refresh 없이 이동이 되도록 하기위해서 custom_fields를 add
          commit('setDrilldownInvestmentDetails', resp)
          dispatch('deactiveLoading', null, { root : true })
          resolve()
        })
      } else {
        resolve()
      }
    })
  },
  editDrilldownFieldValue ({ commit }, field) {
    if (field.recurring) {
      commit('setDrilldownEditedFieldValueRepeating', field)
    } else {
      commit('setDrilldownEditedFieldValue', field)
    }
  },
  getInvestmentReviewBoardReports ({ commit, dispatch }, investmentId) {
    dispatch('activateLoading', null, { root : true })
    investmentsApi.getInvestmentReviewBoardReports(investmentId).then(resp => {
      commit('setInvestmentReviewBoardReports', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getInvestmentReportMinutes ({ commit, dispatch }, investmentId) {
    dispatch('activateLoading', null, { root : true })
    investmentsApi.getInvestmentReportMinutes(investmentId).then(resp => {
      commit('setInvestmentReportMinutes', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  deleteInvestmentDetails ({ state, commit, dispatch }, detailsId) {
    dispatch('activateLoading', null, { root : true })
    investmentsApi.deleteInvestmentDetails(detailsId, state.drilldownInvestment.id).then(resp => {
      if (resp === 'Deleted') {
        commit('removeFromDrilldownInvestmentDetailsById', detailsId)
      } else {
      // TODO (2022.1.04): Show alert message
      console.error('did not delete properly')
      }
      dispatch('deactiveLoading', null, { root : true })
    })
  },
}

const mutations = {
  addToDrilldownInvestmentContacts (state, contact) {
    state.drilldownInvestmentContacts.push(contact)
  },
  addToDrilldownInvestmentDetails (state, details) {
    state.drilldownInvestmentDetails.push(details)
  },
  replaceInDrilldownInvestmentContacts (state, updatedContact) {
    const index = state.drilldownInvestmentContacts.findIndex(contact => contact.id === updatedContact.id)
    if (index >= 0) {
      state.drilldownInvestmentContacts.splice(index, 1, updatedContact)
    }
  },
  removeFromDrilldownInvestmentContacts (state, contactId) {
    const index = state.drilldownInvestmentContacts.findIndex(contact => contact.id === contactId)
    if (index >= 0) {
      state.drilldownInvestmentContacts.splice(index, 1)
    }
  },
  removeFromDrilldownInvestmentDetailsById (state, detailsId) {
    const index = state.drilldownInvestmentDetails.findIndex(details => details.id === detailsId)
    if (index >= 0) {
      state.drilldownInvestmentDetails.splice(index, 1)
    }
  },
  removeFromDrilldownInvestmentDetailsByRowIndex (state, rowIndex) {
    const row = state.drilldownInvestmentDetails[rowIndex]
    if (!row.id) {
      // only remove from unsaved rows
      state.drilldownInvestmentDetails.splice(rowIndex, 1)
    }
  },
  addToInvestments (state, newInvestment) {
    state.investments.unshift(newInvestment)
  },
  addToDrilldownCustomFields (state, addCustomField) {
    const index = state.drilldownInvestmentCustomFields.findIndex(customField => customField.custom_field_value_id === addCustomField.custom_field_value_id)
    if (index < 0) {
      state.drilldownInvestmentCustomFields.push(addCustomField)
    }
  },
  removeFromDrilldownCustomFields (state, removeCustomField) {
    const index = state.drilldownInvestmentCustomFields.findIndex(customField => customField.id === removeCustomField.id && customField.custom_field_value_id === removeCustomField.custom_field_value_id)
    if (index > -1) {
      state.drilldownInvestmentCustomFields.splice(index, 1)
    }
  },
  removeFromInvestments (state, removingInvestmentId) {
    const index = state.investments.findIndex(investment => investment.id === removingInvestmentId)
    if (index >= 0) {
      state.investments.splice(index, 1)
    }
  },
  resetDrilldownInvestment (state) {
    state.drilldownInvestment = DefaultObjects.newEmptyInvestment()
  },
  resetDrilldownInvestmentEditingFields (state) {
    state.drilldownEditedFields = []
  },
  resetInvestmentCustomFields (state) {
    state.drilldownInvestmentCustomFields = []
  },
  setInvestmentCanViewBilling (state, boolean) {
    state.drilldownCanViewBilling = boolean
  },
  setInvestmentApprovalRequestCount (state, count) {
    state.drilldownApprovalRequestCount = count
  },
  addApprovalRequests (state, request) {
    state.drilldownApprovalRequests.unshift(request)
  },
  removeApprovalRequest (state, approvalRequestId) {
    const index = state.drilldownApprovalRequests.findIndex(approvalRequest => approvalRequest.id === approvalRequestId)
    if (index >= 0) {
      state.drilldownApprovalRequests.splice(index, 1)
    }
  },
  setDrilldownApprovalRequests (state, drilldownApprovalRequests) {
    state.drilldownApprovalRequests = drilldownApprovalRequests
  },
  setDrilldownApprovalResponses (state, drilldownApprovalResponses) {
    state.drilldownApprovalResponses = drilldownApprovalResponses
  },
  setCustomFields (state, fromApi) {
    state.drilldownInvestmentCustomFields = fromApi
    state.drilldownInvestmentCustomFields.forEach(field => {
      field.changed = false
    })
  },
  resetCustomFieldChanged (state, fromApi)  {
    // check if this works for field value
    const index = state.drilldownInvestmentCustomFields.findIndex(customField => customField.id === fromApi.id)
    if (index >= 0) {
      state.drilldownInvestmentCustomFields[index].changed = false
    }
  },
  setDrilldownInvestmentContacts (state, fromApi) {
    state.drilldownInvestmentContacts = fromApi
  },
  setDrilldownInvestmentDetails (state, fromApi) {
    state.drilldownInvestmentDetails = fromApi
  },
  setDrilldownEditedFieldValue (state, field)  {
    let foundInEditedIndex = state.drilldownEditedFields.findIndex(editedField => editedField.id === field.id)
    if (foundInEditedIndex === -1) {
      state.drilldownEditedFields.push(cloneDeep(field))
    } else {
      state.drilldownEditedFields[foundInEditedIndex] = cloneDeep(field)
    }
    // for drilldownInvestmentCustomFields update (바뀐 값의 필드 표시조건 적용)
    const index = state.drilldownInvestmentCustomFields.findIndex(x => x.id === field.id)
    if (index >= 0) {
      state.drilldownInvestmentCustomFields.splice(index, 1, field)
    }
  },
  setDrilldownEditedFieldValueRepeating (state, field)  {
    let foundInEditedIndex = state.drilldownEditedFields.findIndex(editedField => editedField.id === field.id && editedField.recurring_index === field.recurring_index)
    if (foundInEditedIndex === -1) {
      state.drilldownEditedFields.push(cloneDeep(field))
    } else {
      state.drilldownEditedFields[foundInEditedIndex] = cloneDeep(field)
    }
    // for drilldownInvestmentCustomFields update (바뀐 값의 필드 표시조건 적용)
    const index = state.drilldownInvestmentCustomFields.findIndex(x => x.id === field.id && x.recurring_index === field.recurring_index)
    if (index >= 0) {
      state.drilldownInvestmentCustomFields.splice(index, 1, field)
    }
  },
  setDrilldownIsViewMode (state, bool) {
    state.drilldownIsViewMode = bool
  },
  setInvestments (state, fromApi) {
    state.investments = fromApi
  },
  setInvestmentsSearch (state, fromApi) {
    state.investmentsSearch = fromApi
  },
  setSearchResults (state, fromApi) {
    state.searchResults = fromApi
  },
  setDrilldownInvestment (state, fromApi) {
    state.drilldownInvestment = fromApi
  },
  updateInvestmentInInvestments (state, updatedInvestment)  {
    const index = state.investments.findIndex(investment => investment.id === updatedInvestment.id)
    if (index >= 0) {
      state.investments.splice(index, 1, updatedInvestment)
    } else {
      state.investments.unshift(updatedInvestment)
    }
  },
  updateDashboardGroupByPosition (state, args) {
    const foundInvestment = state.investments.find(investment => investment.id === args.investmentId)
    if (foundInvestment) {
      const foundPositionIndex = foundInvestment.positions.findIndex(position => position.dashboard_view_id === args.dashboardViewId && position.group_by_value === args.groupByValue)
      if (foundPositionIndex > -1) {
        if (args.newIndex !== null) {
          foundInvestment.positions[foundPositionIndex].sort_index = args.newIndex
        } else {
          foundInvestment.positions.splice(foundPositionIndex, 1)
        }
      } else {
        foundInvestment.positions.push({
          dashboard_view_id: args.dashboardViewId,
          group_by_value: args.groupByValue,
          sort_index: args.newIndex
        })
      }
    }
  },
  updateInvestmentFieldValue (state, updatedFieldWithValue) {
    let investment = state.investments.find(investment => investment.id === updatedFieldWithValue.investment_id)
    let index = investment.custom_fields.findIndex(field => field.id === updatedFieldWithValue.custom_field_template_id)
    if (index >= 0) {
      investment.custom_fields[index].value         = updatedFieldWithValue.value
      investment.custom_fields[index].display_value = updatedFieldWithValue.display_value
      investment.custom_fields[index].value_json    = updatedFieldWithValue.value_json
      investment.custom_fields[index].id            = updatedFieldWithValue.custom_field_template_id
    }
  },
  updateStoreFieldValue (state, updateValueObjectWithId) {
    let found = state.drilldownInvestmentCustomFields.find(field => field.id === updateValueObjectWithId.id)
    if (found) {
      found.value = updateValueObjectWithId.value
      found.value_json = updateValueObjectWithId.value_json
      found.changed = true
    }
  },
  setInvestmentReviewBoardReports (state, fromApi) {
    state.investmentReviewBoardReport = fromApi
  },
  setInvestmentReportMinutes (state, fromApi) {
    state.investmentReportMinutes = fromApi
  },
  setDrilldownEditedFields (state, fields) {
    state.drilldownEditedFields = fields
  },
  pushDrilldownEditedFields (state, field) {
    state.drilldownEditedFields.push(field)
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
