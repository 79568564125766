import Vue          from 'vue'
import Vuex         from 'vuex'
import schedulesApi from '@/api/v1/schedules'
import { getField, updateField } from 'vuex-map-fields'


Vue.use(Vuex)

const state = () => ({
  schedules: [],
  scheduleTypes: [
    '감정기일',
    '공판준비기일',
    '공판기일',
    '변경기일',
    '변론준비기일',
    '변론기일',
    '불변기일',
    '선고기일',
    '심리기일',
    '심문기일',
    '입회기일',
    '조정기일',
    '화해기일',
    '전원회의',
    '소회의',
    '조사출석',
    '내부회의(상담)',
    '외부미팅',
    '기타',
  ],
  clientScheduleTypeFilters: [],
  clientScheduleOwnerFilters: [],
})


const getters = {
  getField,
}


const actions = {
  getSchedules ({ commit, dispatch }, investmentId) {
    let filters = {
      investment_id: investmentId
    }
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      schedulesApi.getSchedules(filters).then(resp => {
        commit('setSchedules', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateSchedule ({ commit, dispatch }, schedule) {
    let forAPI = {
        investment_id: schedule.investment_id,
        schedule_date: schedule.schedule_date,
        schedule_type: schedule.schedule_type,
        user_ids:      schedule.user_ids,
        start_time:    schedule.start_time,
        end_time:      schedule.end_time,
        location:      schedule.location,
        description:   schedule.description,
      }
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      schedulesApi.updateSchedule(schedule.id, forAPI).then(resp => {
        commit('setScheduleInList', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteSchedule ({ commit, dispatch }, scheduleId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      schedulesApi.deleteSchedule(scheduleId).then(resp => {
        commit('removeScheduleFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createSchedule ({ commit, dispatch }, schedule) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      schedulesApi.postSchedule(schedule).then(resp => {
        commit('addSchedule', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}


const mutations = {
  addSchedule (state, schedule) {
    state.schedules.unshift(schedule)
  },
  removeScheduleFromList (state, scheduleId) {
    const index = state.schedules.findIndex(schedule => schedule.id === scheduleId)
    if (index >= 0) {
      state.schedules.splice(index, 1)
    }
  },
  setSchedules (state, fromApi) {
    state.schedules = fromApi
  },
  setScheduleInList (state, schedule)  {
    const index = state.schedules.findIndex(scheduleFromList => scheduleFromList.id === schedule.id)
    if (index >= 0) {
      state.schedules.splice(index, 1, schedule)
    }
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
