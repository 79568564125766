import Vue    from 'vue'
import Vuex   from 'vuex'
import ipoApi from '@/api/v1/ipos'

import { getField, updateField } from 'vuex-map-fields'
import cloneDeep                 from 'lodash/cloneDeep'

Vue.use(Vuex)

const state = () => ({
  accountTypes: ['집합', '벤처', '하이일드', '고유'],
  selectedIpoId: null,
  selectedBrokerId: 0,
  selectedPredictedDate: '',
  selectedSubscriptionDate: '',
  ipos: [],
  ipoSummaries: [],
  ipoSummaryFiles: [],
  ipoResults: [],
  selectedIpo: {},
  ipoPortfolio: [],
  emptyDetail: {
    account_type: '',
    allocated_amount: 0,
    allocated_quantity: 0,
    average_selling_price: 0,
    ipo_id: 0,
    ipo_summary_id: 0,
    lock_up_period: null,
    lockup_expiration_date: null,
    note: null,
    position_close_value: 0,
    position_quantity: 0
  },
})

const getters = {
  hasSelectedIpo: state => {
    return state.selectedIpoId > 0
  },
  getField,
}

const actions = {
  getIpos ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.getIpos().then(resp => {
        commit('setIpos', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getIpoSummaries ({ commit, dispatch, state }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.getIpoSummaries({ipo_id: state.selectedIpoId}).then(resp => {
        commit('setIpoSummaries', resp)
        dispatch('getIpoSummaryFiles')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getIpoSummaryFiles ({ commit, dispatch, state }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.getIpoSummaryFiles({ipo_id: state.selectedIpoId}).then(resp => {
        commit('setIpoSummaryFiles', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getHistoricalIpo ({ commit, dispatch, state }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.getHistoricalIpo({ipo_id: state.selectedIpoId}).then(resp => {
        commit('setIpoResults', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  calculateIpo ({ dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.calculateIpo(params).then(() => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  makeIpoReports ({ dispatch }, ipoId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.makeIpoReports(ipoId).then(() => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  clearIpoResult ({ dispatch }, ipoId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.clearIpoResult(ipoId).then(() => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateSelectedIpo ({ commit, state , rootGetters}){
    return new Promise(resolve => {
      let ipoById = state.ipos.find(ipo => ipo.id === state.selectedIpoId)
      const currentIpoDetailsName = rootGetters['users/currentIpoDetailsName']
      commit('setSelectedIpo', {'ipo': ipoById, 'currentIpoDetailsName': currentIpoDetailsName})
      if (ipoById.id > 0) {
        commit('updateSelectedBrokerId', ipoById.broker_ids[0])
        commit('updateSelectedPredictedDate', ipoById.predicted_date)
        commit('updateSelectedSubscriptionDate', ipoById.subscription_date)
      }
      resolve()
    })
  },
  updateIpoDetail ({ commit, dispatch }, detailData) {
    return new Promise(resolve => {
      commit('setIpoDetail', detailData)
      dispatch('deactiveLoading', null, { root : true })
      resolve()
    })
  },
  patchIpoDetails ({ commit, dispatch }, updatedIpo) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.patchIpoDetails(updatedIpo).then(resp => {
        commit('setIpos', resp)
        let found = resp.find(ipo => ipo.id === updatedIpo.id)
        commit('setSelectedIpo', {'ipo': found})
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getIpoPortfolio ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      ipoApi.getIpoPortfolio().then(resp => {
        commit('setIpoPortfolio', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  patchIpoSummaries ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      commit('updateIpoPortfolio', params)
      ipoApi.patchIpoSummaries(params).then(() => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateIpoIsVenture ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      commit('updateSummariesIsVenture', params)
      ipoApi.updateIpoIsVenture(params).then(() => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setIpos (state, fromApi) {
    state.ipos = fromApi
  },
  setSelectedIpo (state, payload) {
    var rawIpo = cloneDeep(payload.ipo)
    if(payload.currentIpoDetailsName) {
        if (!rawIpo.details[payload.currentIpoDetailsName]) {
          rawIpo.details[payload.currentIpoDetailsName] = {}
        }
        const currentIpoDetailsName = payload.currentIpoDetailsName
        var existingKeys = Object.keys(rawIpo.details[currentIpoDetailsName])
        const allKeys = ['고유배정주식수', '고유확약여부', '캐피탈배정주식수', '캐피탈확약여부']
        allKeys.forEach(function(key) {
          if (!existingKeys.includes(key)) {
            rawIpo.details[currentIpoDetailsName][key] = ''
          }
        })
    }
    state.selectedIpo = rawIpo
  },
  setIpoSummaries (state, fromApi) {
    state.ipoSummaries = fromApi
  },
  setIpoSummaryFiles (state, fromApi) {
    state.ipoSummaryFiles = fromApi
  },
  updateIpoSummaries (state, updateFields) {
    state.ipoSummaries.forEach(summary => {
      summary.is_venture = updateFields.is_venture
      summary.is_listing_transfer = updateFields.is_listing_transfer
      summary.is_lock_up = updateFields.is_lock_up
    })
  },
  updateSummariesIsVenture (state, updateFields) {
    state.ipoSummaries.forEach(summary => {
      summary.is_venture = updateFields.is_venture
    })
  },
  updateIpoPortfolio (state, params) {
    let index = state.ipoPortfolio.findIndex(ipo => ipo.id === params.ipoId)
    if (index >= 0) {
      if (params.key === 'note') {
        state.ipoPortfolio[index].portfolios.filter(port => port.account_type === params.accountType)
          .forEach(portfolio => {
            portfolio.note = params.note
          })
      } else if (params.key === 'average_selling_price') {
        state.ipoPortfolio[index].portfolios.filter(port => port.account_type === params.accountType)
          .forEach(portfolio => {
            portfolio.average_selling_price = params.average_selling_price
          })
      }
    }
  },
  resetIpos (state) {
    state.selectedIpoId = null
    state.selectedIpo = {}
    state.ipoSummaries = []
    state.ipoResults = []
  },
  setIpoResults (state, fromApi) {
    state.ipoResults = fromApi
  },
  updateSelectedBrokerId (state, brokerId) {
    state.selectedBrokerId = brokerId
  },
  updateSelectedPredictedDate (state, date) {
    state.selectedPredictedDate = date
  },
  updateSelectedSubscriptionDate (state, date) {
    state.selectedSubscriptionDate = date
  },
  setIpoDetail (state, detailData) {
    let index = state.ipos.findIndex(ipo => ipo.id === detailData.id)
    if (index >= 0) {
      state.ipos[index].changed = true
      if (detailData.currentIpoDetailsName) {
        if (!state.ipos[index].details[detailData.currentIpoDetailsName]) {
          state.ipos[index].details[detailData.currentIpoDetailsName] = {}
        }
        if (!state.selectedIpo.details[detailData.currentIpoDetailsName]) {
          state.selectedIpo.details[detailData.currentIpoDetailsName] = {}
        }
        if (detailData.type === 'number') {
          state.ipos[index].details[detailData.currentIpoDetailsName][detailData.key] = parseFloat(detailData.value.replace(/,/g, ''))
          state.selectedIpo.details[detailData.currentIpoDetailsName][detailData.key] = parseFloat(detailData.value.replace(/,/g, ''))
        } else {
          state.ipos[index].details[detailData.currentIpoDetailsName][detailData.key] = detailData.value
          state.selectedIpo.details[detailData.currentIpoDetailsName][detailData.key] = detailData.value
        }
      }

      if (detailData.type === 'number') {
        state.ipos[index].details[detailData.key] = parseFloat(detailData.value.replace(/,/g, ''))
        state.selectedIpo.details[detailData.key] = detailData.value
      } else {
        state.ipos[index].details[detailData.key] = detailData.value
        state.selectedIpo.details[detailData.key] = detailData.value
      }
    }
  },
  setIpoPortfolio (state, fromApi) {
    fromApi.forEach(data => {
      var portfolios = []
      state.accountTypes.forEach(accountType => {
        var account = data.portfolios.find(port => port.account_type === accountType)
        if (account === undefined) {
          const portfolio = cloneDeep(state.emptyDetail)
          portfolio['account_type'] = accountType
          portfolios.push(portfolio)
        } else {
          portfolios.push(account)
        }
      })
      data.portfolios = portfolios
    })

    state.ipoPortfolio = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
