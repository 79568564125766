import Vue             from 'vue'
import Vuex            from 'vuex'
import financeInformationsApi     from '@/api/v1/finance_informations'
import cloneDeep       from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newFinanceInformation = {
  id: 0,
  entity_id: 0,
  year: '',
  quarter: '',
  assets: 0,
  sales: 0,
  net_profit: 0,
  operating_profit: 0,
  capital: 0,
  liabilities: 0
}

const state = () => ({
  financeInformations: [],
  drilldownFinanceInformation: cloneDeep(newFinanceInformation),
  showFinanceInformationDetails: false,
})


const getters = {
  drilldownFinanceInformationId: state => {
    return state.drilldownFinanceInformation.id
  },
  drilldownFinanceInformationName: state => {
    return state.drilldownFinanceInformation.year + '년 ' + state.drilldownFinanceInformation.quarter + '분기'
  },
  getField,
}


const actions = {
  getFinanceInformations ({ commit, dispatch }, entityId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      financeInformationsApi.getFinanceInformations(entityId).then(resp => {
        commit('setFinanceInformations', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  setFinanceInformationEdit ({ commit }, financeInformation) {
    commit('setDrilldownFinanceInformation', financeInformation)
  },
  resetDrilldownFinanceInformation ({ commit }) {
    commit('setDrilldownFinanceInformation', cloneDeep(newFinanceInformation))
  },
  updateFinanceInformation ({ state, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      financeInformationsApi.updateFinanceInformation(state.drilldownFinanceInformation.id, state.drilldownFinanceInformation).then(resp => {
        commit('updateDrilldownInList', resp)
        commit('setDrilldownFinanceInformation', newFinanceInformation)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteFinanceInformation ({ commit, dispatch }, financeInformationId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      financeInformationsApi.deleteFinanceInformation(financeInformationId).then(resp => {
        if (resp === 'DeleteSuccess') {
          commit('removeFinanceInformationFromList', financeInformationId)
          commit('setDrilldownFinanceInformation', newFinanceInformation)
          dispatch('deactiveLoading', null, { root : true })
          resolve()
        }
      })
    })
  },
  createFinanceInformation ({ state, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      financeInformationsApi.createFinanceInformation(state.drilldownFinanceInformation).then(resp => {
        commit('addFinanceInformation', resp)
        dispatch('deactiveLoading', null, { root : true })
        dispatch('resetDrilldownFinanceInformation')
        resolve(resp)
      })
    })
  }
}


const mutations = {
  addFinanceInformation (state, financeInformation) {
    state.financeInformations.unshift(financeInformation)
  },
  removeFinanceInformationFromList (state, financeInformationId) {
    const index = state.financeInformations.findIndex(financeInformation => financeInformation.id === financeInformationId)
    if (index >= 0) {
      state.financeInformations.splice(index, 1)
    }
  },
  setFinanceInformations (state, fromApi) {
    state.financeInformations = fromApi
  },
  setShowFinanceInformationDetails (state, openOrClosedState) {
    state.showFinanceInformaitonDetails = openOrClosedState
  },
  setDrilldownFinanceInformation (state, financeInformation) {
    state.drilldownFinanceInformation = financeInformation
  },
  updateDrilldownInList (state, financeInformation)  {
    const index = state.financeInformations.findIndex(c => c.id === financeInformation.id)
    if (index >= 0) {
      state.financeInformations.splice(index, 1, financeInformation)
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
