import Vue                 from 'vue'
import Vuex                from 'vuex'
import listingsApi         from '@/api/v1/listings'
import optionSchedulesApi  from '@/api/v1/option_schedules'
import cloneDeep           from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newListing = {
  id: 0,
  share_code: '',
  name: '',
}

const state = () => ({
  listings: [],
  drilldownListing: cloneDeep(newListing),
  listingCloseData: [],
  filterStartDate: dayjs().format('YYYY-MM-DD'),
  filterEndDate: dayjs().format('YYYY-MM-DD'),
  refixingSchedules: [],
  callSchedules: [],
  putSchedules: [],
})


const getters = {
  drilldownListingId: state => {
    return state.drilldownListing.id
  },
  drilldownListingName: state => {
    return state.drilldownListing.name
  },
  drilldownListingShareCode: state => {
    return state.drilldownListing.share_code
  },
  getField,
}


const actions = {
  getListings ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      listingsApi.getListings(filters).then(resp => {
        commit('setListings', resp)
        commit('setDrilldownListing', cloneDeep(newListing))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getEntityListings ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      listingsApi.getEntityListings(filters).then(resp => {
        commit('setListings', resp)
        commit('setDrilldownListing', cloneDeep(newListing))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getListingCloseData ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      listingsApi.getListingCloseData(filters).then(resp => {
        commit('setListingCloseData', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getListingsOnInvestment ({ commit, dispatch }, investmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      listingsApi.getListingsOnInvestment(investmentId).then(resp => {
        commit('setListings', resp)
        commit('setDrilldownListing', cloneDeep(newListing))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getRefixingSchedules ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      optionSchedulesApi.getRefixingSchedules(filters).then(resp => {
        commit('setRefixingSchedules', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getConversionRightSchedules ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      optionSchedulesApi.getConversionRightSchedules(filters).then(resp => {
        commit('setCallSchedules', resp.filter((option) => option.option_type === 'CALL OPTION'))
        commit('setPutSchedules', resp.filter((option) => option.option_type === 'PUT OPTION'))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateFilterDateRange ({ commit }, dateRange) {
    commit('setFilterDateRange', dateRange)
  },
  setSelectedListing ({ commit }, listing) {
    commit('setDrilldownListing', listing)
  },
  resetDrilldownListing ({ commit }) {
    commit('setDrilldownListing', cloneDeep(newListing))
  },
}


const mutations = {
  setFilterDateRange (state, dateRange) {
    state.filterStartDate = dateRange.startDate
    state.filterEndDate = dateRange.endDate
  },
  setListings (state, fromApi) {
    state.listings = fromApi
  },
  setListingCloseData (state, fromApi) {
    state.listingCloseData = fromApi
  },
  setCallSchedules (state, fromApi) {
    state.callSchedules = fromApi
  },
  setPutSchedules (state, fromApi) {
    state.putSchedules = fromApi
  },
  setRefixingSchedules (state, fromApi) {
    state.refixingSchedules = cloneDeep(fromApi)
  },
  setDrilldownListing (state, listing) {
    state.drilldownListing = listing
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
