import Vue                       from 'vue'
import Vuex                      from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import investmentPlansApi        from '@/api/v1/investment_plans'
import cloneDeep                 from 'lodash/cloneDeep'
import dayjs                     from 'dayjs'

Vue.use(Vuex)

const emptyPlan = {
  id: 0,
  investment_id: 0,
  investment_detail_id: 0,
  fund_id: 0,
  saved_on: null,
  investment_quantity: 0,
  investment_amount: 0,
  proposal_weight: 0,
  nav_weight: 0,
  fund_short_name: '',
  fund_code: '',
  fund_account_name: '',
  fund_end_date: null,
  fund_proposal_weight: 0,
  fund_target_investments: [],
}

// fill in for funds without a plan
function plansWithDefault (response, funds, investmentId=0, investmentDetailId=0) {
  return funds.map(fund => {
    let found = response.find(plan => plan.fund_id === fund.id)
    if (found) {
      return found
    } else {
      let newCopy = cloneDeep(emptyPlan)
      newCopy.fund_id = fund.id
      newCopy.fund_short_name = fund.short_name
      newCopy.fund_code = fund.fund_code
      newCopy.fund_end_date = fund.end_date
      newCopy.fund_proposal_weight = fund.proposal_weight
      newCopy.fund_target_investments = fund.target_investment
      newCopy.fund_account_name = fund.account_name
      newCopy.saved_on = dayjs().format('YYYY-MM-DD')
      newCopy.investment_id = investmentId
      newCopy.investment_detail_id = investmentDetailId
      return newCopy
    }
  })
}

const state = () => ({
  investmentPlans: [],
})

const getters = {
  getField,
}

const actions = {
  getInvestmentPlans ({ commit, rootState, dispatch }, { investmentId, investmentDetailId }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentPlansApi.getInvestmentPlans(investmentId, investmentDetailId).then(resp => {
        commit('setInvestmentPlans', plansWithDefault(resp, rootState.funds.funds, investmentId, investmentDetailId))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createInvestmentPlans ({ commit, rootState, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentPlansApi.postInvestmentPlans(params).then(resp => {
        commit('setInvestmentPlans', plansWithDefault(resp, rootState.funds.funds))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateInvestmentPlans ({ commit, rootState, dispatch }, investmentPlans) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentPlansApi.putInvestmentPlans(investmentPlans).then(resp => {
        commit('setInvestmentPlans', plansWithDefault(resp, rootState.funds.funds))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  deleteInvestmentPlans ({ commit, rootState, dispatch }, investmentPlans) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentPlansApi.putInvestmentPlans(investmentPlans).then(resp => {
        commit('setInvestmentPlans', plansWithDefault(resp, rootState.funds.funds))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setInvestmentPlans (state, fromApi) {
    state.investmentPlans = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
