import Vue                       from 'vue'
import Vuex                      from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import ledgerCodeApi from '@/api/v1/ledger_codes'
import cloneDeep                 from 'lodash/cloneDeep'
// import dayjs                     from 'dayjs'
const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

Vue.use(Vuex)

const emptyLedgerCode = {
  id: 0,
  codeType: '',
  codeName: '',
  codeNote: '',
  subNames: '',
  isShowDetails: false,
}

const state = () => ({
  ledgerCodes: [],
  emptyLedgerCode: emptyLedgerCode,
})

const getters = {
  accountingTypes: state => {
    return state.ledgerCodes.filter(ledger => ledger.codeType === 'accounting_type')
  },
  getField,
}

const actions = {
  getLedgerCodes ({ commit, dispatch }) {
    dispatch('activateLoading', null, { root : true })
    return new Promise(resolve => {
      ledgerCodeApi.getLedgerCodes({search_type: 'all'}).then(resp => {
        let fromApi = camelcaseKeys(resp, { deep: true })
        commit('setLedgerCodes', fromApi)
        dispatch('deactiveLoading', null, { root : true })
        resolve(fromApi)
      })
    })
  },
  postLedgerCodes ({ getters, dispatch }, codeType) {
    dispatch('activateLoading', null, { root : true })
    return new Promise(resolve => {
      let accountingTypes = snakecaseKeys(getters.accountingTypes, { deep: true })
      ledgerCodeApi.postLedgerCodes({code_type: codeType, ledger_codes: accountingTypes}).then(resp => {
        alert(resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}

const mutations = {
  setLedgerCodes (state, fromApi) {
    state.ledgerCodes = []
    fromApi.forEach(resp => {
      state.ledgerCodes.push({ id: resp.id, codeName: resp.codeName, codeType: resp.codeType, codeNote: resp.codeNote, subNames: resp.subNames, isShowDetails: false })
    })
  },
  setAccountingTypeShowDetails(state, {accountingType, isShowDetails}) {
    if (accountingType === 'all') {
      state.ledgerCodes.filter(ledgerCode => ledgerCode.codeType === 'accounting_type').forEach(type => {
        type.isShowDetails = isShowDetails
      })
    } else {
      let found = state.ledgerCodes.find(ledgerCode => ledgerCode.codeType === 'accounting_type' && ledgerCode.codeName === accountingType)
      if (found) {
        found.isShowDetails = isShowDetails
      }
    }
  },
  addLedgerCodes (state, codeType) {
    let ledgerCode = cloneDeep(state.emptyLedgerCode)
    ledgerCode.codeType = codeType
    state.ledgerCodes.push(ledgerCode)
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
