import Vue             from 'vue'
import Vuex            from 'vuex'
import contactsApi     from '@/api/v1/contacts'
import connectionsApi  from '@/api/v1/connections'
import customFieldsApi from '@/api/v1/custom_fields'
import { getField, updateField } from 'vuex-map-fields'
import cloneDeep       from 'lodash/cloneDeep'
import groupBy         from 'lodash/groupBy'
import dayjs           from 'dayjs'

Vue.use(Vuex)

const emptyContact = {
  id: 0,
  contact_name: '',
  shareable: false,
  phone_number: '',
  office_phone_number: '',
  fax_number: '',
  email: '',
  address: '',
  entity_id: 0,
  title: '',
  department: '',
  investment_id: 0,
  custom_fields: [],
}

const state = () => ({
  contacts: [],
  contactsBySearch: [],
  searchResults: [],
  drilldownContact: {},
  drilldownConnections: [],
  editingContact: cloneDeep(emptyContact),
  contactsForInvestment: [],
  drilldownContactFields: [],
  myContact: cloneDeep(emptyContact),
})

function makeContactParams (contact) {
  return {
    id: contact.id,
    contact_name: contact.contact_name,
    shareable: contact.shareable,
    phone_number: contact.phone_number,
    office_phone_number: contact.office_phone_number,
    fax_number: contact.fax_number,
    email: contact.email,
    address: contact.address,
    entity_id: contact.entity_id,
    title: contact.title,
    department: contact.department,
    investment_id: contact.investment_id
  }
}

const getters = {
  contactFieldsByTemplateId: state => {
    return groupBy(state.drilldownContactFields, 'id')
  },
  contactNameFromId: state => id => {
    let found = state.contacts.find(contact => contact.id === id)
    return (found) ? found.contact_name : ''
  },
  contactFromId: state => id => {
    return state.contacts.find(contact => contact.id === id)
  },
  drilldownContactId: state => {
    return state.drilldownContact.id
  },
  isEditingContact: state => {
    return state.drilldownContact !== {}
  },
  getField,
}


const actions = {
  addEditingContact ({ state, dispatch }) {
    return new Promise(resolve => {
      resolve(dispatch('createContact', state.editingContact))
    })
  },
  updateEditingContact ({ state, dispatch }) {
    dispatch('updateContactFieldValues', state.editingContact.id)
    dispatch('updateContact', state.editingContact)
  },
  cancelEditing ({ dispatch }) {
    dispatch('resetEditingContact')
    dispatch('resetContactFields')
    dispatch('modalClose', null, { root : true })
  },
  contactSearch ({ commit, dispatch }, searchString) {
    dispatch('activateLoading', null, { root : true })
    contactsApi.searchContacts(searchString).then(resp => {
      commit('setSearchResults', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  createContact (context, contact) {
    return new Promise(resolve => {
      contactsApi.postContact(contact).then(resp => {
        context.commit('addToContacts', resp)
        context.dispatch('updateContactFieldValues', resp.id)
        context.dispatch('resetEditingContact')
        resolve(resp)
      })
    })
  },
  contactDrilldownOpen ({ commit, dispatch }, contact) {
    if (contact) {
      commit('setDrilldownContact', contact)
      dispatch('getConnectionByContact', contact.id)
    } else {
      dispatch('resetDrilldownContact')
    }
    dispatch('sidepanelOpen', { componentName: 'ContactDrilldown', directory: 'views/contacts' }, { root : true })
  },
  deleteContact (context, contactId) {
    return new Promise(resolve => {
      contactsApi.deleteContact(contactId).then(() => {
        context.commit('removeFromContacts', contactId)
        resolve()
      })
    })
  },
  editContact (context, contact) {
    context.commit('setEditingContact', contact)
  },
  setEmptyContact (context) {
    context.commit('setEditingContact', emptyContact)
  },
  getContacts (context) {
    contactsApi.getContacts().then(resp => {
      context.commit('setContacts', resp)
    })
  },
  getContactsByName (context, contactName) {
    return new Promise(resolve => {
      contactsApi.searchContacts(contactName).then(resp => {
        context.commit('setContactsBySearch', resp)
        resolve(resp)
      })
    })
  },
  searchContacts ({ commit, dispatch }, searchString) {
    dispatch('activateLoading', null, { root : true })
    contactsApi.searchContacts(searchString).then(resp => {
      commit('setContacts', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getContactsForLimitedPartner (context, limitedPartnerId) {
    contactsApi.getContactsForLimitedPartner(limitedPartnerId).then(resp => {
      context.commit('setContacts', resp)
    })
  },
  getMyContact (context) {
    return new Promise(resolve => {
      contactsApi.getMyContact().then(resp => {
        context.commit('setMyContact', resp)
        resolve(resp)
      })
    })
  },
  getContactCustomFields ({ commit, dispatch }, contactId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      customFieldsApi.getContactCustomFields(contactId).then(resp => {
        commit('setContactFields', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getConnectionByContact (context, contactId) {
    return new Promise(resolve => {
      contactsApi.getConnectionByContact(contactId).then(resp => {
        context.commit('setDrilldownConnection', resp)
        resolve()
      })
    })
  },
  resetContactSearchResults ({ commit }) {
    commit('setSearchResults', [])
  },
  resetDrilldownContact ({ commit }) {
    commit('setDrilldownContact', {})
  },
  resetEditingContact ({ commit }) {
    commit('setEditingContact', cloneDeep(emptyContact))
  },
  updateContact ({ commit, dispatch }, contact) {
    let contactParams = makeContactParams(contact)
    return new Promise(resolve => {
      contactsApi.updateContact(contactParams.id, contactParams).then(resp => {
        commit('replaceInContacts', resp)
        dispatch('resetEditingContact')
        if (contactParams.id === getters.drilldownContactId) {
          commit('setDrilldownContact', resp)
        }
        dispatch('modalClose', null, { root : true })
        resolve(resp)
      })
    })
  },
  uploadContactsFile (context, fileData) {
    return new Promise(resolve => {
      context.dispatch('activateLoading', null, { root : true })
      contactsApi.uploadContactsFile(fileData).then(resp => {
        context.dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateMyContact (context, contact) {
    return new Promise(resolve => {
      contactsApi.updateMyContact(contact.id, contact).then(resp => {
        context.commit('setMyContact', resp)
        resolve()
      })
    })
  },
  updateConnection (context, connection) {
    return new Promise(resolve => {
      connectionsApi.updateConnection(connection.id, connection).then(resp => {
        context.commit('replaceInConnections', resp)
        context.dispatch('modalClose', null, { root : true })
        resolve()
      })
    })
  },
  createConnection (context, connection) {
    return new Promise(resolve => {
      connectionsApi.postConnection(connection).then(resp => {
        context.dispatch('modalClose', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateContactField (context, field) {
    context.commit('replaceOrInsertContactField', field)
  },
  resetContactFields ({ commit }) {
    commit('resetContactFields')
  },
  updateContactFieldValues ({ state, dispatch }, contactId) {
    if (state.drilldownContactFields) {
      let data = new FormData()
      state.drilldownContactFields.forEach(field => {
        if (field.custom_field_value_id) {
          data.append('custom_field_values[][id]', field.custom_field_value_id)
        }
        data.append('custom_field_values[][investment_id]', 0)
        data.append('custom_field_values[][custom_field_template_id]', field.id)
        data.append('custom_field_values[][custom_field_name]', field.custom_field_name)
        data.append('custom_field_values[][entity_id]', state.editingContact.entity_id)
        data.append('custom_field_values[][contact_id]', contactId)
        if (field.field_type === 'table') {
          data.append('custom_field_values[][value_json]', JSON.stringify(field.value_json))
        }
        if (field.value === null) {
        // dont send - need this to delete
        } else if (field.value instanceof File) {
          data.append('custom_field_values[][value]', field.value, field.value.name)
        } else if (field.field_type === 'date') {
          if (dayjs(field.value).isValid()) {
            data.append('custom_field_values[][value]', dayjs(field.value).format('YYYY-MM-DD'))
          }
        } else if (field.field_type === 'date-range') {
          const dateRangeValue = (field.value[0] && field.value[0] !== 'Invalid Date') ?
                                  [dayjs(field.value[0]).format('YYYY-MM-DD'), dayjs(field.value[1]).format('YYYY-MM-DD')] :
                                  ''
          data.append('custom_field_values[][value]', dateRangeValue)
        } else {
          data.append('custom_field_values[][value]', field.value)
        }
      })
      if (!data.entries().next().done) {
        return new Promise(resolve => {
          dispatch('activateLoading', null, { root : true })
          customFieldsApi.updateCustomFieldValues(data).then(resp => {
            dispatch('resetContactFields')
            dispatch('deactiveLoading', null, { root : true })
            resolve(resp)
          })
        })
      }
    }
  }
}

const mutations = {
  addToContacts (state, contact) {
    state.contacts.push(contact)
  },
  removeFromContacts (state, contactId) {
    const index = state.contacts.findIndex(contact => contact.id === contactId)
    if (index >= 0) {
      state.contacts.splice(index, 1)
    }
  },
  replaceInContacts (state, contactFromApi) {
    const index = state.contacts.findIndex(contact => contact.id === contactFromApi.id)
    if (index >= 0) {
      state.contacts.splice(index, 1, contactFromApi)
    }
  },
  setDrilldownContact (state, contact) {
    state.drilldownContact = contact
  },
  setContacts (state, fromApi) {
    state.contacts = fromApi
  },
  setContactsBySearch (state, fromApi) {
    state.contactsBySearch = fromApi
  },
  setMyContact (state, fromApi) {
    state.myContact = fromApi
  },
  setEditingContact (state, contact) {
    state.editingContact = contact
  },
  selectEntityForEditingContact (state, entity) {
    state.editingContact.entity_id = entity.entityId
    state.editingContact.entity_name = entity.entityName
  },
  replaceOrInsertContactField (state, field) {
    let foundInEditedIndex = state.drilldownContactFields.findIndex(editedField => editedField.id === field.id)
    if (foundInEditedIndex === -1) {
      state.drilldownContactFields.push(field)
    } else {
      state.drilldownContactFields[foundInEditedIndex] = cloneDeep(field)
    }
  },
  setSearchResults (state, contacts) {
    state.searchResults = contacts
  },
  setDrilldownConnection (state, connections) {
    state.drilldownConnections = connections
  },
  replaceInConnections (state, fromApi) {
    const index = state.drilldownConnections.findIndex(connection => connection.id === fromApi.id)
    if (index >= 0) {
      state.drilldownConnections.splice(index, 1, fromApi)
    }
  },
  setEditingContactName (state, name) {
    state.editingContact.contact_name = name
  },
  resetContactFields (state) {
    state.drilldownContactFields = []
  },
  setContactFields (state, fromApi) {
    state.drilldownContactFields = fromApi
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
