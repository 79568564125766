import base from './base'

const getLimitedPartners = () => base
  .get('/limited_partners/').then(resp => resp.data)

const getCommitmentsForLP = (limitedPartnerId) => base
  .get(`/commitments/?limited_partner_id=${limitedPartnerId}`).then(resp => resp.data)

const getLimitedPartner = (limitedPartnerId) => base
  .get(`/limited_partners/${limitedPartnerId}`).then(resp => resp.data)

const postLimitedPartner = (limitedPartnerParams, contentType='application/json') => base
  .post('/limited_partners/', {limited_partner: limitedPartnerParams}, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const postCommitment = (commitmentParams, contentType='application/json') => base
  .post('/commitments/', {commitment: commitmentParams}, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateLimitedPartner = (limitedPartnerId, limitedPartnerParams, contentType='application/json') => base
  .patch(`/limited_partners/${limitedPartnerId}`, {limited_partner: limitedPartnerParams}, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteLimitedPartner = (limitedPartnerId) => base
  .delete(`/limited_partners/${limitedPartnerId}`).then(resp => resp.data)

export default {
  deleteLimitedPartner: deleteLimitedPartner,
  getLimitedPartners: getLimitedPartners,
  getCommitmentsForLP: getCommitmentsForLP,
  getLimitedPartner: getLimitedPartner,
  postLimitedPartner: postLimitedPartner,
  postCommitment: postCommitment,
  updateLimitedPartner: updateLimitedPartner
}
