import base from './base'

const getTmsPiDivs = () => base
  .get('/tms_pi_divs').then(resp => resp.data)

const postTmsPiDiv = (params) => base
  .post('/tms_pi_divs', params).then(resp => resp.data)

const patchTmsPiDiv = (params) => base
  .patch(`/tms_pi_divs/${params.id}`, params).then(resp => resp.data)

const deleteTmsPiDiv = (divId) => base
  .delete(`/tms_pi_divs/${divId}`).then(resp => resp.data)

const getTmsPiFills = () => base
  .get('/tms_pi_fills').then(resp => resp.data)

const postTmsPiFill = (params) => base
  .post('/tms_pi_fills/', params).then(resp => resp.data)

const patchTmsPiFill = (params) => base
  .patch(`/tms_pi_fills/${params.id}`, params).then(resp => resp.data)

const deleteTmsPiFill = (fillId) => base
  .delete(`/tms_pi_fills/${fillId}`).then(resp => resp.data)

const getPiPortfolios = () => base
  .get('/pi_portfolios').then(resp => resp.data)

const patchPiPortfoliosBookPrice = (params) => base
  .patch(`/pi_portfolios/${params.id}`, params).then(resp => resp.data)

const calculatePiPortfolio = () => base
  .patch('/pi_portfolios/calculate_pi_portfolios').then(resp => resp.data)

export default {
  getTmsPiDivs: getTmsPiDivs,
  postTmsPiDiv: postTmsPiDiv,
  patchTmsPiDiv: patchTmsPiDiv,
  deleteTmsPiDiv: deleteTmsPiDiv,

  getTmsPiFills: getTmsPiFills,
  postTmsPiFill: postTmsPiFill,
  patchTmsPiFill: patchTmsPiFill,
  deleteTmsPiFill: deleteTmsPiFill,

  getPiPortfolios: getPiPortfolios,
  patchPiPortfoliosBookPrice: patchPiPortfoliosBookPrice,
  calculatePiPortfolio: calculatePiPortfolio,
}
