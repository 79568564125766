import base from './base'

const getLpBlinds = (filter) => base
  .get('/lp_blinds/', {params: filter}).then(resp => resp.data)

const createLpBlinds = (lpBlindParams, contentType='multipart/form-data') => base
  .post('/lp_blinds/', lpBlindParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const getLpHistories = (filter) => base
  .get('/lp_histories/', {params: filter}).then(resp => resp.data)

const getLpHistorieGroups = (filter) => base
  .get('/lp_histories/history_date', {params: filter}).then(resp => resp.data)

const createLpHistories = (lpHistoryParams) => base
  .post('/lp_histories/', lpHistoryParams).then(resp => resp.data)

const getLpInformations = (filter) => base
  .get('/lp_informations/', {params: filter}).then(resp => resp.data)

const createLpInformations = (lpInformationParams) => base
  .post('/lp_informations/', lpInformationParams).then(resp => resp.data)

const getLpDetailPortfolios = (filter) => base
  .get('/lp_portfolios/lp_detail_portfolios', {params: filter }).then(resp => resp.data)

const patchLpShareCode = (lpShareCode) => base
  .patch('/lp_portfolios/update_lp_share_code', {lp_share_code: lpShareCode}).then(resp => resp.data)

const getLpProfit = (filter) => base
  .get('/lp_portfolios/get_lp_profit', {params: filter }).then(resp => resp.data)

const getLpPortfolios = (filter) => base
  .get('/lp_portfolios/', {params: filter }).then(resp => resp.data)

const updateLpPortfolios = (filter) => base
  .patch(`/lp_portfolios/${filter.share_code}`, filter).then(resp => resp.data)

const getLpContacts = (filter) => base
  .get('/lp_contacts/', {params: filter}).then(resp => resp.data)

const patchLpContacts = (lpContactsParams, contentType='application/json') => base
  .patch('/lp_contacts/update_lp_contacts', lpContactsParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

export default {
  getLpBlinds: getLpBlinds,
  createLpBlinds: createLpBlinds,
  getLpHistories: getLpHistories,
  getLpHistorieGroups: getLpHistorieGroups,
  createLpHistories: createLpHistories,
  getLpInformations: getLpInformations,
  createLpInformations: createLpInformations,
  getLpDetailPortfolios: getLpDetailPortfolios,
  patchLpShareCode: patchLpShareCode,
  getLpProfit: getLpProfit,
  getLpPortfolios: getLpPortfolios,
  updateLpPortfolios: updateLpPortfolios,
  getLpContacts: getLpContacts,
  patchLpContacts: patchLpContacts,
}
