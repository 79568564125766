import Vue from 'vue'
import Vuex from 'vuex'
import companySettingsApi from '@/api/v1/reach_company_settings'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  companySettings: {
    id: 0,
    apply_user_group_to_investments: false,
  },
})

const getters = {
  getOperatingMode: (state) => {
    return (state.companySettings.id > 0 && state.companySettings.operating_mode) ? state.companySettings.operating_mode : null
  },
  getCustomFieldStorageMode: (state) => {
    return (state.companySettings.id > 0 && state.companySettings.custom_field_storage_mode) ? state.companySettings.custom_field_storage_mode : null
  },
  isMsStorageMode: (state, getters) => {
    return getters.getCustomFieldStorageMode === 'sharepoint'
  },
  getField,
}

const actions = {
  getCompanySettings ({ commit }) {
    return new Promise(resolve => {
      companySettingsApi.getCompanySettings().then(resp => {
        commit('setCompanySetting', resp[0])
        resolve()
      })
    })
  },
  createUpdateCompanySettings ({ commit, dispatch }, settings) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      companySettingsApi.postCompanySettings(settings).then(resp => {
        commit('setCompanySetting', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setCompanySetting (state, settings) {
    if (settings) {
      state.companySettings = settings
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
