import base from './base'

const getListings = (filters) => base
  .get('/listing_data/', { params: filters }).then(resp => resp.data)

const getListing = (listingId) => base
  .get(`/listing_data/${listingId}`).then(resp => resp.data)

const getEntityListings = (filters) => base
  .get('/listing_data/listing_data_on_entity/', { params: filters }).then(resp => resp.data)

const getListingCloseData = (filters) => base
  .get('/listing_close_data/', { params: filters }).then(resp => resp.data)

const postListing = (listingParams, contentType='application/json') => base
  .post('/listing_data/', listingParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const patchListing = (listingId, listingParams, contentType='application/json') => base
  .patch(`/listing_data/${listingId}`, listingParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteListing = (listingId) => base
  .delete(`/listing_data/${listingId}`).then(resp => resp.data)

const getListingsOnInvestment = (investmentId) => base
  .get(`/listing_data/`, investmentId).then(resp => resp.data)

export default {
  getListings: getListings,
  getListing: getListing,
  getListingCloseData: getListingCloseData,
  postListing: postListing,
  patchListing: patchListing,
  deleteListing: deleteListing,
  getEntityListings: getEntityListings,
  getListingsOnInvestment: getListingsOnInvestment
}
