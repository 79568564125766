import Vue        from 'vue'
import Vuex       from 'vuex'
import foldersApi from '@/api/v1/folders'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  folders: [],
  folderLists: [],
  defaultFolder: {
    folderId: 0,
    pathName: ''
  }
})

const getters = {
  getField,
}

const actions = {
  getFolders ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      foldersApi.getFolders().then(resp => {
        commit('setFolders', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getFolderLists ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      foldersApi.getFolderLists().then(resp => {
        commit('setFolderLists', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getInvestmentFolder ({ commit, dispatch }, investmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      foldersApi.getInvestmentFolder(investmentId).then(resp => {
        commit('setFolders', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createFolder (context, folderInfo) {
    return new Promise(resolve => {
      foldersApi.createFolder(folderInfo.parentFolderId, folderInfo.name).then(resp => {
        resolve(resp)
      })
    })
  },
  deleteFolder (context, folderId) {
    return new Promise(resolve => {
      foldersApi.deleteFolder(folderId).then(resp => {
        resolve(resp)
      })
    })
  },
  renameFolder (context, folderInfo) {
    return new Promise(resolve => {
      foldersApi.renameFolder(folderInfo.folderId, folderInfo.name).then(resp => {
        resolve(resp)
      })
    })
  },
  moveFolder (context, folderInfo) {
    return new Promise(resolve => {
      foldersApi.moveFolder(folderInfo.folderId, folderInfo.parentFolderId).then(resp => {
        resolve(resp)
      })
    })
  },
  moveFile (context, folderInfo) {
    return new Promise(resolve => {
      foldersApi.moveFile(folderInfo.folderId, folderInfo.customFieldValueId).then(resp => {
        resolve(resp)
      })
    })
  },
  defaultFolderSetting ({ commit }, defaultFolderInfo) {
    commit('setDefaultFolder', defaultFolderInfo)
  },
}

const mutations = {
  setFolders (state, fromApi) {
    state.folders = fromApi
  },
  setFolderLists (state, fromApi) {
    state.folderLists = fromApi
  },
  setDefaultFolder(state, defaultFolderInfo) {
    state.defaultFolder.pathName = defaultFolderInfo.pathName
    state.defaultFolder.folderId = defaultFolderInfo.folderId
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
