import base from './base'

const getPortfolios = (filter) => base
  .get('/portfolios', {params: filter}).then(resp => resp.data)

const getGroupPortfolios = (filter) => base
  .get('/portfolios/group_portfolios', {params: filter}).then(resp => resp.data)

const getPortfolio = (investmentId) => base
  .get(`/portfolios/${investmentId}`).then(resp => resp.data)

const getInvestmentPortfolios = (investmentId) => base
  .get(`/portfolios/investment_portfolios/?id=${investmentId}`).then(resp => resp.data)

const getFundPortfolios = (investmentId) => base
  .get(`/portfolios/fund_portfolios/?id=${investmentId}`).then(resp => resp.data)

const getPortfolioStrategy = (filter) => base
  .get('/portfolios/strategy', {params: filter}).then(resp => resp.data)

const getPortfolioSector = (filter) => base
  .get('/portfolios/sector', {params: filter}).then(resp => resp.data)

const getPortfolioPeriod = (filter) => base
  .get('/portfolios/period', {params: filter}).then(resp => resp.data)

export default {
  getPortfolios: getPortfolios,
  getGroupPortfolios: getGroupPortfolios,
  getPortfolio: getPortfolio,
  getInvestmentPortfolios: getInvestmentPortfolios,
  getFundPortfolios: getFundPortfolios,
  getPortfolioStrategy: getPortfolioStrategy,
  getPortfolioSector: getPortfolioSector,
  getPortfolioPeriod: getPortfolioPeriod,
}
