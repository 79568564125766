import base from './base'

const deleteInvestment = (investmentId) => base
  .delete(`/investments/${investmentId}`).then(resp => resp.data)

const getInvestments = (getInvestmentParams) => base
  .get(`/investments/`, { params: getInvestmentParams }).then(resp => resp.data)

const getInvestmentDrilldownOverview = (investmentId) => base
  .get(`/investments/investment_drilldown_overview?investment_id=${investmentId}`).then(resp => resp.data)

// needs to be a poast because of the way we are sending filter params in the body
const getFilteredInvestments = (getInvestmentParams) => base
  .post(`/investments/filtered`, getInvestmentParams ).then(resp => resp.data)

const getInvestmentsSearch = () => base
  .get(`/investments/get_investments_search`).then(resp => resp.data)

const getMyInvestmentsSearch = () => base
  .get(`/investments/get_my_investments_search`).then(resp => resp.data)

const getInvestmentCard = (investmentId, dashboardViewId) => base
  .get(`/investments/${investmentId}?dashboard_view_id=${dashboardViewId}&mode=card`).then(resp => resp.data)

const getInvestmentInfo = (investmentId, dashboardViewId) => base
  .get(`/investments/${investmentId}?dashboard_view_id=${dashboardViewId}&mode=detail`).then(resp => resp.data)

const postInvestment = (investmentParams, dashboardViewId) => base
  .post(`/investments/?dashboard_view_id=${dashboardViewId}`, investmentParams).then(resp => resp.data)

const searchInvestments = (searchParams) => base
  .get(`/investments/search_by_text/?search_text=${searchParams.search_text}&search_at=${searchParams.search_at}`).then(resp => resp.data)

const updateInvestment = (investmentId, investmentParams, dashboardViewId) => base
  .patch(`/investments/${investmentId}?dashboard_view_id=${dashboardViewId}`, investmentParams).then(resp => resp.data)

const updateInvestmentStatus = (investmentId, investmentStatus) => base
  .patch(`/investments/${investmentId}/update_investment_status?investment_status=${investmentStatus}`).then(resp => resp.data)

const updateDashboardPositions = (params, dashboardViewId) => base
  .patch(`/dashboard_group_by_positions/update_positions?dashboard_view_id=${dashboardViewId}`, params).then(resp => resp.data)

const getInvestmentReviewBoardReports = (investmentId) => base
  .get(`/investments/investment_review_board_report/?investment_id=${investmentId}`).then(resp => resp.data)

const getInvestmentReportMinutes = (investmentId) => base
  .get(`/investments/investment_report_minutes/?investment_id=${investmentId}`).then(resp => resp.data)

const batchUpdateInvestmentDetails = (investmentId, investmentDetailsParams, contentType='application/json') => base
  .patch(`/investment_details/batch_update?investment_id=${investmentId}`, investmentDetailsParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const getInvestmentDetails = (investmentId) => base
  .get(`/investment_details/?investment_id=${investmentId}`).then(resp => resp.data)

const deleteInvestmentDetails = (detailsId, investmentId) => base
  .delete(`/investment_details/${detailsId}?investment_id=${investmentId}`).then(resp => resp.data)

const updateInvestmentDetail = (investmentDetail) => base
  .patch(`/investment_details/${investmentDetail.investment_detail_id}`, investmentDetail).then(resp => resp.data)

const getInvestmentForLedger = (filter) => base
  .get('/investments/investment_for_ledger/', { params: filter}).then(resp => resp.data)

const getSummaryByInvestment = (investmentId) => base
  .get(`/investment_details/summary_by_investment?investment_id=${investmentId}`).then(resp => resp.data)

const createMsFolder = (investmentId) => base
  .patch(`/investments/${investmentId}/create_ms`).then(resp => resp.data)

export default {
  getInvestments: getInvestments,
  getFilteredInvestments: getFilteredInvestments,
  getInvestmentsSearch: getInvestmentsSearch,
  getMyInvestmentsSearch: getMyInvestmentsSearch,
  getInvestmentCard: getInvestmentCard,
  getInvestmentInfo: getInvestmentInfo,
  searchInvestments: searchInvestments,
  updateInvestment: updateInvestment,
  getInvestmentReviewBoardReports: getInvestmentReviewBoardReports,
  getInvestmentReportMinutes:      getInvestmentReportMinutes,
  getInvestmentDrilldownOverview:  getInvestmentDrilldownOverview,
  getInvestmentForLedger:          getInvestmentForLedger,
  getSummaryByInvestment:          getSummaryByInvestment,
  updateInvestmentStatus:       updateInvestmentStatus,
  batchUpdateInvestmentDetails: batchUpdateInvestmentDetails,
  updateDashboardPositions:     updateDashboardPositions,
  getInvestmentDetails:      getInvestmentDetails,
  deleteInvestment:        deleteInvestment,
  updateInvestmentDetail:       updateInvestmentDetail,
  deleteInvestmentDetails: deleteInvestmentDetails,
  postInvestment: postInvestment,
  createMsFolder: createMsFolder,
}
