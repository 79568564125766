import base from './base'

const getInvestmentPlans = (investmentId, investmentDetailId) => base
  .get(`/investment_plans/?investment_id=${investmentId}&investment_detail_id=${investmentDetailId}`, {}).then(resp => resp.data)

const postInvestmentPlans = (params) => base
  .post('/investment_plans/', params).then(resp => resp.data)

const putInvestmentPlans = (params) => base
  .put(`/investment_plans/${params.id}`, params).then(resp => resp.data)

const deleteInvestmentPlans = (params) => base
  .delete(`/investment_plans/${params.id}`)

export default {
  getInvestmentPlans: getInvestmentPlans,
  postInvestmentPlans: postInvestmentPlans,
  putInvestmentPlans: putInvestmentPlans,
  deleteInvestmentPlans: deleteInvestmentPlans,
}
