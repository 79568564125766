import Vue          from 'vue'
import Vuex         from 'vuex'
import limitedPartnersApi from '@/api/v1/limited_partners'
import cloneDeep    from 'lodash/cloneDeep'

Vue.use(Vuex)

const newLimitedPartner = {
  id: 0,
  limited_partner_name: '',
}

const state = () => ({
  limitedPartners: [],
  commitments: [],
  drilldownLimitedPartner: cloneDeep(newLimitedPartner),
})


const getters = {
  drilldownLimitedPartnerId: state => {
    return state.drilldownLimitedPartner.id
  },
  isEditingLimitedPartner: state => {
    return !!(state.drilldownLimitedPartner.id)
  },
}


const actions = {
  getLimitedPartners ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      limitedPartnersApi.getLimitedPartners().then(resp => {
        commit('setLimitedPartners', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getCommitmentsForLP ({ commit, dispatch }, limitedPartnerId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      limitedPartnersApi.getCommitmentsForLP(limitedPartnerId).then(resp => {
        commit('setCommitments', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  limitedPartnerDetailsOpen ({ commit, dispatch }, limitedPartner) {
    if (limitedPartner) {
      dispatch('getCommitmentsForLP', limitedPartner.id).then(() => {
        commit('setDrilldownLimitedPartner', limitedPartner)
      })
    } else {
      commit('resetDrilldownLimitedPartner')
    }
    dispatch('sidepanelOpen', { componentName: 'LimitedPartnerDrilldown', directory: 'views/limited_partners' }, { root : true })
  },
  updateLimitedPartner ({ commit, dispatch, state }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      limitedPartnersApi.updateLimitedPartner(state.drilldownLimitedPartner.id, state.drilldownLimitedPartner).then(resp => {
        commit('updateDrilldownInList', resp)
        commit('setDrilldownLimitedPartner', newLimitedPartner)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteLimitedPartner ({ commit, dispatch }, limitedPartnerId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      limitedPartnersApi.deleteLimitedPartner(limitedPartnerId).then(resp => {
        commit('removeLimitedPartnerFromList', resp.id)
        commit('setDrilldownLimitedPartner', newLimitedPartner)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createLimitedPartner ({ commit, dispatch, state }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      limitedPartnersApi.postLimitedPartner(state.drilldownLimitedPartner).then(resp => {
        commit('addLimitedPartner', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  createCommitment ({ commit, dispatch }, commitment) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      limitedPartnersApi.postCommitment(commitment).then(resp => {
        commit('addCommitment', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}


const mutations = {
  addLimitedPartner (state, limitedPartner) {
    state.limitedPartners.unshift(limitedPartner)
  },
  addCommitment (state, commitment) {
    state.commitments.unshift(commitment)
  },
  removeLimitedPartnerFromList (state, limitedPartnerId) {
    const index = state.limitedPartners.findIndex(limitedPartner => limitedPartner.id === limitedPartnerId)
    if (index >= 0) {
      state.limitedPartners.splice(index, 1)
    }
  },
  setLimitedPartners (state, fromApi) {
    state.limitedPartners = fromApi
  },
  setDrilldownLimitedPartner (state, limitedPartner) {
    state.drilldownLimitedPartner = limitedPartner
  },
  setCommitments (state, fromApi) {
    state.commitments = fromApi
  },
  updateDrilldownName (state, limitedPartnerName) {
    state.drilldownLimitedPartner.limited_partner_name = limitedPartnerName
  },
  updateDrilldownInList (state, limitedPartner)  {
    const index = state.limitedPartners.findIndex(c => c.id === limitedPartner.id)
    if (index >= 0) {
      state.limitedPartners.splice(index, 1, limitedPartner)
    }
  },
  resetDrilldownLimitedPartner (state) {
    state.drilldownLimitedPartner = cloneDeep(newLimitedPartner)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
