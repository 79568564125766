import cloneDeep from 'lodash/cloneDeep'

class DefaultObjects {

 static newEmptyInvestment () {
    return cloneDeep({
      entity_id: 0,
      entity_name: '',
      incomplete_required_fields: [],
      id: 0,
      investment_name: '',
      owner_ids: [],
      owner_name: '',
      counterparty: '',
      investment_statis: 'active',
    })
  }

  static newEmptyDashboardView () {
    return cloneDeep({
      id: 0,
      calendar_field_template_ids: [],
      dashboard_view_name: '',
      display_on_card_template_ids: [],
      filters: {
        logical_operator: 'and',
        expressions: []
      },
      group_by_custom_template_id: 0,
      group_by_values: [],
      mapping_field_template_ids: [],
      schedule_types: [],
      shared: false,
      show_list_view_id: false,
      show_list_view_name: true,
      show_case_infos: false,
      show_empty_display_on_values: false,
      show_group_by_value_unassigned: false,
      tab_type: 'none',
      tab_custom_field_template_id: 0,
      view_layout: 'Card',
    })
  }


  static selectableInvestmentStatuses () {
    return [
        'active',
        'hold',
        'dropped',
      ]
  }
}

export default DefaultObjects
