import Vue       from 'vue'
import Vuex      from 'vuex'
import dailyPerformancesApi from '@/api/v1/daily_performances'

import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  dailyPerformances: [],
})

const getters = {
  getField,
}

const actions = {
  getDailyPerformances ({ commit, dispatch }, date) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dailyPerformancesApi.getDailyPerformances(date).then(resp => {
        commit('setDailyPerformances', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  uploadDailyPerformancesFile (context, formData) {
    return new Promise(resolve => {
      context.dispatch('activateLoading', null, { root : true })
      dailyPerformancesApi.uploadDailyPerformancesFile(formData).then(resp => {
        context.dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateDailyPerformances ({ commit, dispatch }, dailyPerformances) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dailyPerformancesApi.updateDailyPerformances(dailyPerformances).then(resp => {
        commit('setDailyPerformances', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  importMiraeDailyPerformances ({ commit, dispatch }, date) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dailyPerformancesApi.importMiraeDailyPerformances(date).then(resp => {
        commit('setDailyPerformances', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setDailyPerformances (state, fromApi) {
    state.dailyPerformances = fromApi
  },
  resetDailyPerformances (state) {
    state.dailyPerformances = []
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
