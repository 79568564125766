import base from './base'

const getInvestmentEvents = (fromDate, toDate) => base
  .get('/investment_events/', { params: { from_date: fromDate, to_date: toDate } }).then(resp => resp.data)

const getInvestmentEvent = (investmentEventId) => base
  .get(`/investment_events/${investmentEventId}`).then(resp => resp.data)

const postInvestmentEvent = (investmentEventParams, contentType='application/json') => base
  .post('/investment_events/', investmentEventParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const postInvestmentEventByAnnouncement = (announcementId, contentType='application/json') => base
  .post('/investment_events/create_by_announcement', { announcement_id: announcementId }, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateInvestmentEvent = (investmentEventId, investmentEventParams, contentType='application/json') => base
  .patch(`/investment_events/${investmentEventId}`, investmentEventParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteInvestmentEvent = (investmentEventId) => base
  .delete(`/investment_events/${investmentEventId}`).then(resp => resp.data)

export default {
  deleteInvestmentEvent: deleteInvestmentEvent,
  getInvestmentEvents: getInvestmentEvents,
  getInvestmentEvent: getInvestmentEvent,
  postInvestmentEvent: postInvestmentEvent,
  postInvestmentEventByAnnouncement: postInvestmentEventByAnnouncement,
  updateInvestmentEvent: updateInvestmentEvent,
}
