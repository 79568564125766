import Vue                 from 'vue'
import Vuex                from 'vuex'
import CorporateAnnouncementsApi from '@/api/v1/corporate_announcements'

Vue.use(Vuex)

function convertArrayToCSV (announcements) {
  let string = ''
  string += stringForHeader()
  announcements.forEach(announcement => {
    string += convertRowToString(announcement)
  })
  return string
}

function stringForHeader () {
  let string =''
  string += `Report Name\t`
  string += `Share Code\t`
  string += `Name\t`
  string += `Action\t`
  string += `Created On\t`
  string += `Receipt Number\t`
  string += `신주의 종류와 수 (보통주)\t`
  string += `신주의 종류와 수 (우선주)\t`
  string += `신주의 종류와 수 (기타주)\t`
  string += `자금조달의 목적(시설자금)\t`
  string += `자금조달의 목적(영업양수자금)\t`
  string += `자금조달의 목적(운영자금)\t`
  string += `자금조달의 목적(채무상환자금)\t`
  string += `자금조달의 목적(타법인 증권 취득자금)\t`
  string += `자금조달의 목적(기타자금)\t`
  string += `증자방식\t`
  string += `신주배정기준일\t`
  string += `청약예정일(우리 사주조합 시작일)\t`
  string += `청약예정일(우리 사주조합 종료일)\t`
  string += `청약예정일(구주주 시작일)\t`
  string += `청약예정일(구주주 종료일)\t`
  string += `납입일\t`
  string += `배당기산일\t`
  string += `상장예정일\t`
  string += `할인율또는할증율`
  string += `\n`
  return string
}

function convertRowToString (announcement) {
  let string = ''
  if (announcement) {
    string += `${announcement.share_code}\t`
    string += `${announcement.report_name}\t`
    string += `${announcement.share_code}\t`
    string += `${announcement.name}\t`
    string += `${announcement.receipt_number}\t`
    string += `${announcement.action}\t`
    string += `${announcement.receipt_date}\t`
    string += `${announcement.details['보통주']}\t`
    string += `${announcement.details['우선주']}\t`
    string += `${announcement.details['기타주']}\t`
    string += `${announcement.details['시설자금']}\t`
    string += `${announcement.details['영업양수자금']}\t`
    string += `${announcement.details['운영자금']}\t`
    string += `${announcement.details['채무상환자금']}\t`
    string += `${announcement.details['타법인증권취득자금']}\t`
    string += `${announcement.details['기타자금']}\t`
    string += `${announcement.details['증자방식']}\t`
    string += `${announcement.details['신주배정기준일']}\t`
    string += `${announcement.details['우리사주조합시작일']}\t`
    string += `${announcement.details['우리사주조합종료일']}\t`
    string += `${announcement.details['구주주시작일']}\t`
    string += `${announcement.details['구주주종료일']}\t`
    string += `${announcement.details['납입일']}\t`
    string += `${announcement.details['배당기산일']}\t`
    string += `${announcement.details['상장예정일']}\t`
    string += `${announcement.details['할인율또는할증율']}`
    string += `\n`
  }
  return string
}

const state = () => ({
  corporateAnnouncements: [],
  drilldownCorporateAnnouncement: {}
})

const getters = {
  getCSVString: state => {
    return convertArrayToCSV(state.corporateAnnouncements)
  },
}

const actions = {
  getCorporateAnnouncements ({ commit, dispatch }, date) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      CorporateAnnouncementsApi.getCorporateAnnouncements(date).then(resp => {
        commit('updateCorporateAnnouncements', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  putCorporateAnnouncements ({ state, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let filteredData = state.corporateAnnouncements.filter(item => item.user_update)
      CorporateAnnouncementsApi.putCorporateAnnouncements(filteredData).then(resp => {
        console.log('Resp : ', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateUserCheck ({ commit, dispatch }, checkData) {
    commit('setUserCheck', checkData)
    dispatch('deactiveLoading', null, { root : true })
  },
  updateUserDetail ({ commit, dispatch }, detailData) {
    commit('setUserDetail', detailData)
    dispatch('deactiveLoading', null, { root : true })
  },
  corporateAnnouncementStockDetailsOpen ({ commit, dispatch }, corporateAnnouncement) {
    commit('setDrilldownCorporateAnnouncement', corporateAnnouncement)
    dispatch('sidepanelOpen', { componentName: 'CorporateAnnouncementStockDetails', directory: 'views/corporate_announcements' }, { root : true })
  },
  corporateAnnouncementIpoDetailsOpen ({ commit, dispatch }, corporateAnnouncement) {
    commit('setDrilldownCorporateAnnouncement', corporateAnnouncement)
    dispatch('sidepanelOpen', { componentName: 'CorporateAnnouncementIpoDetails', directory: 'views/corporate_announcements' }, { root : true })
  },
  corporateAnnouncementBondDetailsOpen ({ commit, dispatch }, corporateAnnouncement) {
    commit('setDrilldownCorporateAnnouncement', corporateAnnouncement)
    dispatch('sidepanelOpen', { componentName: 'CorporateAnnouncementBondDetails', directory: 'views/corporate_announcements' }, { root : true })
  }
}

const mutations = {
  updateCorporateAnnouncements (state, values) {
    state.corporateAnnouncements = values
  },
  setDrilldownCorporateAnnouncement (state, corporateAnnouncement) {
    state.drilldownCorporateAnnouncement = corporateAnnouncement
  },
  setUserCheck (state, checkData) {
    let index = state.corporateAnnouncements.findIndex(announcement => announcement.id === checkData.id)
    if (index >= 0) {
      state.corporateAnnouncements[index].user_check = checkData.check
      state.corporateAnnouncements[index].user_update = true
    }
  },
  setUserDetail (state, detailData) {
    let index = state.corporateAnnouncements.findIndex(announcement => announcement.id === detailData.id)
    if (index >= 0) {
      state.corporateAnnouncements[index].user_details[detailData.key] = detailData.value
      state.corporateAnnouncements[index].user_update = true
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
