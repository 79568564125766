import base from './base'

const getLedgerCodes = (filters) => base
  .get('/ledger_codes/', { params: filters }).then(resp => resp.data)

const postLedgerCodes = (ledgerCodeParams) => base
  .post('/ledger_codes/', ledgerCodeParams).then(resp => resp.data)

const getFundList = () => base
  .get('/ledger_codes/fund_list').then(resp => resp.data)

export default {
  getLedgerCodes: getLedgerCodes,
  postLedgerCodes: postLedgerCodes,
  getFundList: getFundList,
}
