<template>
  <div class='home'>
    <div class='flex flex-row justify-center h-screen'>
      <img :src='logoURL' class='w-40' />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
      logoURL: process.env.VUE_APP_CLIENT_BASE_URL + '/logo_reach_horizontal.svg'
    }
  },
  watch: {
    authCompletedLoading: {
      handler: function (newVal) {
        if (newVal) {
          if (this.$auth.isAuthenticated) {
            this.$router.push({ name: 'Portfolios' })
          } else {
            this.$auth.loginWithRedirect()
          }
        }
      },
      immediate: true,
    }
  },
  computed: {
    authCompletedLoading () {
      return this.$auth && !this.$auth.loading
    }
  },
}
</script>
