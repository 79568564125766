import Vue       from 'vue'
import Vuex      from 'vuex'
import fundsApi  from '@/api/v1/funds'

import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'

Vue.use(Vuex)

const emptyFund = {
  id: 0,
  name: '',
  short_name: '',
  fund_code: '',
  ksd_fund_code: '',
  start_date: '',
  end_date: '',
  entity_id: 0,
  entity_name: '',
  contact_id: 0,
  account_name: '',
  is_venture: false,
  is_public_offering: false,
  is_ipo_participation: false,
  managers: { main_manager: null, sub_manager: null },
  target_investment: [],
  non_marketable_objectives: 0,
  proposal_weight: 0,
}

const state = () => ({
  funds: [],
  ipoFundLists: [],
  drilldownFund: cloneDeep(emptyFund),
  emptyFund: emptyFund,
})

const getters = {
  fundShortNamesFromIds: state => fundIds => {
    let found = ''
    if (fundIds) {
      found = state.funds.filter(fund => fundIds.includes(fund.id)).map(fund => fund.short_name).join(',')
    }
    return found
  },
  getField,
}

const actions = {
  createFund ({ commit, dispatch }, fund) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      fundsApi.postFund(fund).then(resp => {
        commit('setDrilldownFund', resp)
        commit('addDrilldownFundToFunds')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  deleteFund ({ commit, dispatch }, fund) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      fundsApi.deleteFund(fund.id).then(() => {
        commit('removeFromFunds', fund)
        dispatch('fundDetailsClose')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getFunds ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      fundsApi.getFunds().then(resp => {
        commit('setFunds', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getIpoFundLists ({ commit, dispatch }, ipoId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      fundsApi.getIpoFundLists(ipoId).then(resp => {
        commit('setIpoFundLists', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  fundDetailsOpen ({ commit, dispatch }, fund) {
    commit('setDrilldownFund', fund)
    dispatch('sidepanelOpen', { componentName: 'FundDetails', directory: 'views/ipo' }, { root : true })
  },
  updateFund ({ commit, dispatch }, fund) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      fundsApi.putFund(fund).then(resp => {
        commit('setDrilldownFund', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateIpoFundLists ({ state, dispatch }, ipoId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      fundsApi.updateIpoFundLists({ipo_id: ipoId, ipo_funds: state.ipoFundLists}).then(() => {
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  copyIpoFundLists ({ commit, dispatch }, params) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      fundsApi.copyIpoFundLists({ipo_id: params.ipoId, target_ipo_id: params.targetIpoId}).then((resp) => {
        commit('setIpoFundLists', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  }
}

const mutations = {
  addDrilldownFundToFunds (state) {
    let foundIndex = state.funds.findIndex(iFund => iFund.id === state.drilldownFund.id)
    if (foundIndex < 0) {
      state.funds.unshift(state.drilldownFund)
    }
  },
  removeFromFunds (state, fund) {
    let foundIndex = state.funds.findIndex(iFund => iFund.id === fund.id)
    if (foundIndex > -1) {
      state.funds.splice(foundIndex, 1)
    }
  },
  setFunds (state, fromApi) {
    state.funds = fromApi
  },
  setIpoFundLists (state, fromApi) {
    state.ipoFundLists = fromApi
  },
  setDrilldownFund (state, fund) {
    state.drilldownFund = fund
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
