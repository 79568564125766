import base from './base'

const getChangeLogs = (investmentId, filterText, customFieldTemplateId) => {
  const textParam = (filterText) ? `&filter_text=${filterText}` : ''
  const templateIdParam = (customFieldTemplateId > 0) ? `&custom_field_template_id=${customFieldTemplateId}` : ''
  return base.get(`/change_logs/?investment_id=${investmentId}${textParam}${templateIdParam}`).then(resp => resp.data)
}

const createComment = (logParams, contentType='multipart/form-data') => base
  .patch('/change_logs/create_comment/', logParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateComment = (comment) => base
  .patch('/change_logs/update_comment/', comment).then(resp => resp.data)

const deleteComment = (id) => base
  .patch('/change_logs/delete_comment/', {
    id: id
  }).then(resp => resp.data)

  const getCommentById = (id) => base
  .get(`/change_logs/${id}/comment_by_id`).then(resp => resp.data)

export default {
  getChangeLogs: getChangeLogs,
  createComment: createComment,
  updateComment: updateComment,
  deleteComment: deleteComment,
  getCommentById: getCommentById,
}
