import Vue       from 'vue'
import Vuex      from 'vuex'
import legalFundsApi  from '@/api/v1/legal_funds'


Vue.use(Vuex)

const state = () => ({
  legalFunds: [],
})


const actions = {
  getLegalFunds ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      legalFundsApi.getLegalFunds().then(resp => {
        commit('setLegalFunds', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}


const mutations = {
  setLegalFunds (state, fromApi) {
    state.legalFunds = fromApi.sort((a,b) => a.sort_index - b.sort_index)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
