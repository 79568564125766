import base from './base'
import axios from 'axios'

const fileBase = axios.create({
  baseURL: `${process.env.VUE_APP_FILES_BASE_URL}/api/v1`,
  headers: {
    Authorization: {
      toString () {
        return window.localStorage.getItem('access_token.bbridge_reach')
      }
    },
    'Content-Type': 'multipart/form-data'
  }
})

const getCustomFieldTemplates = () => base
  .get('/custom_field_templates/').then(resp => resp.data)

const getCustomFieldTemplate = (customFieldTemplateId) => base
  .get(`/custom_field_templates/${customFieldTemplateId}`).then(resp => resp.data)

const getCustomFieldsForInvestment = (investmentId, dashboardViewId, fieldType = 'all', reportType = null) => base
  .get(`/custom_fields/`, {
    params: {
      template_type: 'investment',
      investment_id: investmentId,
      dashboard_view_id: dashboardViewId,
      report_type: reportType,
      field_type: fieldType
    }
  }).then(resp => resp.data)

const getCustomFieldValuesOfType = (fieldType) => base
  .get(`/custom_field_values/field_values_of_type?field_type=${fieldType}`).then(resp => resp.data)

const postCustomFieldTemplate = (customFieldTemplateParams, contentType='application/json') => base
  .post('/custom_field_templates/', customFieldTemplateParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateCustomFieldTemplate = (customFieldTemplateId, customFieldTemplateParams, contentType='application/json') => base
  .patch(`/custom_field_templates/${customFieldTemplateId}`, customFieldTemplateParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const postCustomFieldValue = (customFieldValue) => base
  .post('/custom_field_values/', customFieldValue).then(resp => resp.data)

const postFileFieldValue = (customFieldValue) => fileBase
  .post('/custom_field_values/', customFieldValue).then(resp => resp.data)

const updateCustomFieldValues = (customFieldValues) => base
  .patch('/custom_field_values/update_values/', customFieldValues).then(resp => resp.data)

const updateFileFieldValues = (customFieldValues) => fileBase
  .patch('/custom_field_values/update_values/', customFieldValues).then(resp => resp.data)

const deleteCustomFieldTemplate = (customFieldTemplateId) => base
  .delete(`/custom_field_templates/${customFieldTemplateId}`).then(resp => resp.data)

const updateCustomFieldOrdering = (customFieldTemplates, contentType='multipart/form-data') => base
  .patch(`/custom_field_templates/update_ordering/`, customFieldTemplates, {headers: {'Content-Type': contentType }}).then(resp => resp.data)

const createOrUpdateCustomFieldValue = (customFieldValue) => base
  .post('/custom_field_values/create_or_update_value/', {custom_field_value: customFieldValue}).then(resp => resp.data)

const getContactCustomFields = (contactId) => base
  .get(`/custom_fields/`, {
    params: {
      template_type: 'contact',
      contact_id: contactId
    }
  }).then(resp => resp.data)

export default {
  deleteCustomFieldTemplate: deleteCustomFieldTemplate,
  getCustomFieldTemplates: getCustomFieldTemplates,
  getCustomFieldsForInvestment: getCustomFieldsForInvestment,
  getCustomFieldTemplate: getCustomFieldTemplate,
  getCustomFieldValuesOfType: getCustomFieldValuesOfType,
  postCustomFieldTemplate: postCustomFieldTemplate,
  updateCustomFieldTemplate: updateCustomFieldTemplate,
  postCustomFieldValue: postCustomFieldValue,
  postFileFieldValue: postFileFieldValue,
  updateCustomFieldValues: updateCustomFieldValues,
  updateFileFieldValues: updateFileFieldValues,
  updateCustomFieldOrdering: updateCustomFieldOrdering,
  createOrUpdateCustomFieldValue: createOrUpdateCustomFieldValue,
  getContactCustomFields: getContactCustomFields
}
