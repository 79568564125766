import Vue       from 'vue'
import Vuex      from 'vuex'
import brokersApi  from '@/api/v1/brokers'

import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  brokers: [],
  showBrokerDetails: false,
  drilldownBroker: {},
})

const getters = {
  getField,
}

const actions = {
  getBrokers ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      brokersApi.getBrokers().then(resp => {
        commit('setBrokers', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setBrokers (state, fromApi) {
    state.brokers = fromApi
  },
  setDrilldownBroker (state, broker) {
    state.drilldownBroker = broker
  },
  setShowBrokerDetails (state, openOrClosedState) {
    state.showBrokerDetails = openOrClosedState
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
