import Vue         from 'vue'
import Vuex        from 'vuex'
import meetingNotesApi from '@/api/v1/meeting_notes'
import cloneDeep       from 'lodash/cloneDeep'


Vue.use(Vuex)

const newMeetingNote = {
  id: 0,
  content: '',
  title: '',
  date: new Date(),
  contacts: [],
  investments: [],
  internal_attendee_field: {},
}


const state = () => ({
  meetingNotes: [],
  searchResults: [],
  editingMeetingNote: cloneDeep(newMeetingNote),
})


const getters = {
}


const actions = {
  openMeetingNote ({ dispatch }) {
    dispatch('sidepanelOpen', { componentName: 'MeetingNoteEdit', directory: 'components/meeting_notes', isFull: true }, { root : true })
  },
  openNewMeetingNote ({ commit, dispatch }) {
    commit('resetEditingMeetingNote')
    dispatch('sidepanelOpen', { componentName: 'MeetingNoteEdit', directory: 'components/meeting_notes', isFull: true }, { root : true })
  },
  doneEditingMeetingNote ({ commit, dispatch }) {
    commit('resetEditingMeetingNote')
    dispatch('sidepanelClose', null, { root : true })
  },
  getMeetingNotes (context) {
    meetingNotesApi.getMeetingNotes().then(resp => {
      context.commit('setMeetingNotes', resp)
    })
  },
  getInvestmentMeetingNotes (context, investmentId) {
    meetingNotesApi.getMeetingNotes(investmentId).then(resp => {
      context.commit('setMeetingNotes', resp)
    })
  },
  getMeetingNoteDetails ({ commit, dispatch }, meetingNoteId) {
    dispatch('activateLoading', null, { root : true })
    meetingNotesApi.getMeetingNote(meetingNoteId).then(resp => {
      commit('setEditingMeetingNote', resp[0])
      dispatch('openMeetingNote')
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  createMeetingNote (context, meetingNote) {
    context.dispatch('activateLoading', null, { root : true })
    return new Promise(resolve => {
      meetingNotesApi.postMeetingNote(meetingNote).then(resp => {
        context.commit('addToMeetingNotes', resp)
        context.dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateMeetingNote (context, meetingNote) {
    return new Promise(resolve => {
      context.dispatch('activateLoading', null, { root : true })
      meetingNotesApi.updateMeetingNote(meetingNote.id, meetingNote).then(resp => {
        context.commit('replaceInMeetingNotes', resp)
        context.dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteMeetingNote (context, meetingNoteId) {
    return new Promise(resolve => {
      meetingNotesApi.deleteMeetingNote(meetingNoteId).then(resp => {
        context.commit('removeFromMeetingNotes', resp.id)
        resolve(resp)
      })
    })
  },
  meetingNoteSearch ({ commit, dispatch }, searchString) {
    dispatch('activateLoading', null, { root : true })
    meetingNotesApi.searchMeetingNotes(searchString).then(resp => {
      commit('setSearchResults', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  resetMeetingNoteSearchResults ({ commit }) {
    commit('setSearchResults', [])
  },
}


const mutations = {
  addToMeetingNotes (state, meetingNote) {
    state.meetingNotes.unshift(meetingNote)
  },
  replaceInMeetingNotes (state, fromApi) {
    const index = state.meetingNotes.findIndex(meetingNote => meetingNote.id === fromApi.id)
    if (index >= 0) {
      state.meetingNotes.splice(index, 1, fromApi)
    }
  },
  removeFromMeetingNotes (state, meetingNoteId) {
    const index = state.meetingNotes.findIndex(meetingNote => meetingNote.id === meetingNoteId)
    if (index >= 0) {
      state.meetingNotes.splice(index, 1)
    }
  },
  setMeetingNotes (state, fromApi) {
    state.meetingNotes = fromApi
  },
  setSearchResults (state, meetingNotes) {
    state.searchResults = meetingNotes
  },
  setEditingMeetingNote (state, fromApi) {
    state.editingMeetingNote = fromApi
  },
  resetEditingMeetingNote (state) {
    state.editingMeetingNote = cloneDeep(newMeetingNote)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
