import axios from 'axios'
import router from '@/router'
import { getInstance } from '@/auth'


axios.defaults.headers.post['Content-Type']  = 'application/json; charset=utf-8'
axios.defaults.headers.patch['Content-Type'] = 'application/json; charset=utf-8'


const base = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/api/v1`,
  headers: {
    Authorization: {
      toString () {
        return window.localStorage.getItem('access_token.bbridge_reach')
      }
    },
    'Content-Type': 'application/json; charset=utf-8'
  }
})


base.interceptors.response.use(response => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    if (router.currentRoute.path.includes('mobile')) {
      console.log('send 401 to mobile app', error.response)
      /* eslint-disable */
      try { apiErrorResponseType.postMessage('401') } catch (err) { 
        console.log('error object from postMessage:', err)
      }
      /* eslint-enable */
    } else {
      let auth0Instance = getInstance()
      auth0Instance.logout()
    }
  } else if (error.response.status === 403) {
    router.push({ name: 'NewUser' }) // send to waiting for team page
  } else  {
    return Promise.reject(error)
  }
})

export default base
