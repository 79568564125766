<template>
  <component :is='layout' id='app'>
    <router-view :layout.sync='layout' />
  </component>
</template>

<script>
export default {
  name: `App`,
  components: {
    'default-layout': () => import('@/layouts/DefaultLayout.vue'),
    'mobile-app-webview-layout': () => import('@/layouts/MobileAppWebviewLayout.vue'),
  },
  computed: {
    divLayoutRoutes () {
      return [
        'Home',
        'NewUser',
        'InvestmentReviewReport',
        'InvestmentReportMinutes',
        'ReportsSignPage',
        'IpoDraft',
        'CommentView',
      ]
    },
    mobileLayoutRoutes () {
      return [
        'MobilePortfolios',
        'MobileIpoPortfolio',
      ]
    },
    layout () {
      if (this.useMobileLayout()) {
        return 'mobile-app-webview-layout'
      } else if (this.useDivLayout()) {
        return 'div'
      } else {
        return 'default-layout'
      }
    },
  },
  methods: {
    useDivLayout () {
      return this.divLayoutRoutes.includes(this.$route.name)
    },
    useMobileLayout () {
      return this.$route.path.includes('mobile/')
    },
  },
  mounted () {
    document.title = process.env.VUE_APP_TITLE
  }
}
</script>

<style lang="scss">

html {
  min-height:100%;


}

body {
  background: linear-gradient(0deg, rgba(246,249,252,1) 30%, rgba(255,255,255,1) 90%) no-repeat;
}

@media print {
  body {
    background: #FFF;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}

.profit-text-color {
  color: #a42828;
}

.loss-text-color {
  color: #244d7e;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.linked-text-container {
  a {
    @apply underline;
  }
  a:hover {
    @apply bg-gray-100;
  }
}

:checked + span {
  @apply text-blue-900 font-normal;
}


@font-face {
  font-family: "HANBatang";
  src: url("/HANBatang.ttf") format('truetype');
}

@font-face {
  font-family: "HANDotum";
  src: url("/HANDotum.ttf") format('truetype');
}

.han-batang {
  font-family: HANBatang;
}
.han-dotum {
  font-family: HANDotum;
}

.tabular-number {
  @apply whitespace-no-wrap;
  font-family: 'IBM Plex Mono', sans-serif;
}
.tabular-units-text {
  @apply opacity-75 inline-block font-light;
  font-size: 0.9em;
}

</style>
