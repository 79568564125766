import Vue                 from 'vue'
import Vuex                from 'vuex'
import investmentEventsApi from '@/api/v1/investment_events'
import { getField, updateField } from 'vuex-map-fields'
import cloneDeep           from 'lodash/cloneDeep'
import dayjs               from 'dayjs'

Vue.use(Vuex)

const emptyCalendarEvent = {
  id: 0,
  title: '',
  event_on: '',
  description: '',
  internal_owner_ids: [],
  external_contact_ids: []
}

const state = () => ({
  investmentEvents: [],
  editingCalendarEvent: cloneDeep(emptyCalendarEvent),
  showDayDetails: false,
})

const getters = {
  getField,
}

function formatItemForCalendar (item) {
  let formattedItem = cloneDeep(item)
  formattedItem['title'] = item.title
  formattedItem['startDate'] = new Date(item.event_on)
  return formattedItem
}

function formatForCalendar (response) {
  if (Array.isArray(response)) {
    return response.map(item => {
      return formatItemForCalendar(item)
    })
  } else {
    return formatItemForCalendar(response)
  }
}

const actions = {
  cancelEditing ({ dispatch }) {
    dispatch('modalClose', null, { root : true })
  },
  createInvestmentEvent ({ commit, dispatch }, investmentEvent) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentEventsApi.postInvestmentEvent(investmentEvent).then(resp => {
        let formatted = formatForCalendar(resp)
        commit('addInvestmentEvent', formatted)
        dispatch('deactiveLoading', null, { root : true })
        resolve(formatted)
      })
    })
  },
  updateInvestmentEvent (context, investmentEvent) {
    return new Promise(resolve => {
      investmentEventsApi.updateInvestmentEvent(investmentEvent.id, investmentEvent).then(resp => {
        let formatted = formatForCalendar(resp)
        context.commit('replaceInCalendar', formatted)
        context.dispatch('modalClose', null, { root : true })
        resolve(formatted)
      })
    })
  },
  createInvestmentEventByAnnouncement ({ dispatch }, announcementId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentEventsApi.postInvestmentEventByAnnouncement(announcementId).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(formatForCalendar(resp))
      })
    })
  },
  getInvestmentEvents ({ commit, dispatch }, dates) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentEventsApi.getInvestmentEvents(dates.fromDate, dates.toDate).then(resp => {
        commit('updateInvestmentEvents', formatForCalendar(resp))
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  newCalendarEvent ({dispatch}, date) {
    let editFormat = cloneDeep(emptyCalendarEvent)
    if (dayjs(date).isValid()) {
      editFormat.event_on = date
      dispatch('calendarEventEditOpen', editFormat)
    } else {
      editFormat.event_on = ''
      dispatch('calendarEventEditOpen', editFormat)
    }
  },
  calendarEventEditOpen ({ commit, dispatch }, calendarEvent) {
    commit('setEditingCalendarEvent', calendarEvent)
    dispatch('sidepanelOpen', { componentName: 'CalendarEventEdit', directory: 'components/calendar' }, { root : true })
    
  },
  deleteInvestmentEvent ({ commit, dispatch }, investmentEventId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentEventsApi.deleteInvestmentEvent(investmentEventId).then(resp => {
        commit('removeEventFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  addInvestmentEvent (state, investmentEvent) {
    state.investmentEvents.push(investmentEvent)
  },
  updateInvestmentEvents (state, values) {
    state.investmentEvents = values
  },
  replaceInCalendar (state, fromApi) {
    const index = state.investmentEvents.findIndex(event => event.id === fromApi.id)
    if (index >= 0) {
      state.investmentEvents.splice(index, 1, fromApi)
    }
  },
  setEditingCalendarEvent (state, calendarEvent) {
    state.editingCalendarEvent = calendarEvent
  },
  removeEventFromList (state, investmentEventId) {
    const index = state.investmentEvents.findIndex(event => event.id === investmentEventId)
    if (index >= 0) {
      state.investmentEvents.splice(index, 1)
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
