import base from './base'

const getIpos = () => base
  .get('/ipos/').then(resp => resp.data)

const getIpoSummaries = (ipoId) => base
  .get('/ipo_summaries/', { params: ipoId }).then(resp => resp.data)

const getIpoSummaryFiles = (ipoId) => base
  .get('/ipo_summaries/summary_files', { params: ipoId} ).then(resp => resp.data)

const getHistoricalIpo = (ipoId) => base
  .get('/historical_ipos/', { params: ipoId }).then(resp => resp.data)

const calculateIpo = (params) => base
  .post('/ipo_summaries/calculate_ipo', params).then(resp => resp.data)

const makeIpoReports = (ipoId) => base
  .post('/ipos/make_ipo_reports', { ipo_id: ipoId }).then(resp => resp.data)

const clearIpoResult = (ipoId) => base
  .patch('/ipo_summaries/clear_ipo_results', { ipo_id: ipoId }).then(resp => resp.data)

const patchIpoDetails = (ipo) => base
  .patch('/ipos/update_ipo_details', { ipo: ipo }).then(resp => resp.data)

const getIpoPortfolio = () => base
  .get('/historical_ipos/ipo_portfolio').then(resp => resp.data)

const patchIpoSummaries = (params) => base
  .patch('/ipo_summaries/update_ipo_summaries', params).then(resp => resp.data)

const updateIpoIsVenture = (params) => base
  .patch('/ipo_summaries/update_is_venture', params).then(resp => resp.data)

export default {
  getIpos: getIpos,
  getIpoSummaries: getIpoSummaries,
  getIpoSummaryFiles: getIpoSummaryFiles,
  getHistoricalIpo: getHistoricalIpo,
  calculateIpo: calculateIpo,
  makeIpoReports: makeIpoReports,
  clearIpoResult: clearIpoResult,
  patchIpoDetails: patchIpoDetails,
  getIpoPortfolio: getIpoPortfolio,
  patchIpoSummaries: patchIpoSummaries,
  updateIpoIsVenture: updateIpoIsVenture,
}
