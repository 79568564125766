import Vue             from 'vue'
import Vuex            from 'vuex'
import entitiesApi     from '@/api/v1/entities'
import ksicApi         from '@/api/v1/ksic'
import newdealItemsApi from '@/api/v1/newdeal_items'
import cloneDeep       from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newEntity = {
  entity_name: '',
  country: '',
  ceo_name: '',
  ceo_percentage: 0,
  foundation_date: '',
  company_number: '',
  major_business: '',
  major_item: '',
  market: '',
  business_type: '',
  business_code: '',
  business_scale: '',
  total_issue_quantity: 0,
  outstanding_stock_quantity: 0,
  is_uniform_stocks: false,
  is_venture: false,
  is_ipo: false,
  is_surtax: false,
  ceo_career: '',
  staff_date: '',
  staff_number: 0,
  entity_type: '',
  company_type: '',
  business_license_number: '',
  currency: '',
  address: '',
  client_name: '',
  birth_date: '',
  resident_number: '',
  english_client_name: '',
  business_conditions: '',
  business_item: '',
  entity_comment: '',
  attached_file: '',
  self_owned_quantity: 0,
  venture_date: '',
  newdeal_code: '',
  newdeal_name: '',
  major_stockholders: '',
  venture_type: '',
  estimation_type: '',
  company_code: '',
  sector_large: '',
  sector_medium: '',
  sector_small: '',
  underwriter: '',
  underwriting_status: '',
  id: 0
}

const state = () => ({
  entities: [],
  drilldownEntity: cloneDeep(newEntity),
  industries: [],
  ventureIndustries: [],
  showEntityDetails: false,
  sectorCodes: [],
})


const getters = {
  drilldownEntityId: state => {
    return state.drilldownEntity.id
  },
  drilldownEntityName: state => {
    return state.drilldownEntity.entity_name
  },
  drilldownEntityRequiredFieldsValid: (state, getters) => {
    return getters.nameValid && getters.companyCodeValid
  },
  businessLicenseNumberValid: state => {
    return !!state.drilldownEntity.business_license_number
  },
  companyTypeValid: state => {
    return !!state.drilldownEntity.company_type
  },
  entityTypeValid: state => {
    return !!state.drilldownEntity.entity_type
  },
  isCompanyType: state => {
    return state.drilldownEntity.entity_type !== '개인'
  },
  nameValid: state => {
    return !!state.drilldownEntity.entity_name
  },
  companyCodeValid: state => {
    return !!state.drilldownEntity.company_code && state.drilldownEntity.company_code.length >= 5
  },
  getField,
}


const actions = {
  getBusinessLicenseNumberValid ({ state }) {
    return new Promise(resolve => {
      entitiesApi.getBusinessLicenseNumberValid(state.drilldownEntity.business_license_number).then(resp => {
        resolve(resp)
      })
    })
  },
  getEntities ({ commit, dispatch }, filters={}) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.getEntities(filters).then(resp => {
        commit('setEntities', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getEntity (context, entityId) {
    return new Promise(resolve => {
      entitiesApi.getEntity(entityId).then(resp => {
        resolve(resp)
      })
    })
  },
  searchEntitiesByName (context, searchText) {
    return new Promise(resolve => {
      entitiesApi.searchEntities(searchText).then(resp => {
        resolve(resp)
      })
    })
  },
  getIndustries ({ commit }, filters) {
    return new Promise(resolve => {
      ksicApi.getKsic(filters).then(resp => {
        commit('setIndustries', resp)
        resolve(resp)
      })
    })
  },
  getVentureIndustries ({ commit }, filters) {
    return new Promise(resolve => {
      newdealItemsApi.getNewdealItems(filters).then(resp => {
        commit('setVentureIndustries', resp)
        resolve(resp)
      })
    })
  },
  entityDetailsOpen ({ commit, dispatch }, entity) {
    commit('setDrilldownEntity', entity)
    dispatch('sidepanelOpen', { componentName: 'EntityDetails', directory: 'views/entities' }, { root : true })
  },
  resetDrilldownEntity ({ commit }) {
    commit('setDrilldownEntity', cloneDeep(newEntity))
  },
  onlySetDrilldownEntity ({ commit }, entity) {
    commit('setDrilldownEntity', entity)
  },
  updateEntity ({ state, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.updateEntity(state.drilldownEntity.id, state.drilldownEntity).then(resp => {
        commit('updateDrilldownInList', resp)
        commit('setDrilldownEntity', newEntity)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  uploadEntity (context, fileInfo) {
    let data = new FormData()
    data.append('id', fileInfo.id)
    data.append('attached_file', fileInfo.attachedFile)
    return new Promise(resolve => {
      entitiesApi.uploadEntity(data).then(resp => {
        resolve(resp)
      })
    })
  },
  deleteEntity ({ commit, dispatch }, entityId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.deleteEntity(entityId).then(resp => {
        if (resp) {
          commit('removeEntityFromList', resp.id)
          commit('setDrilldownEntity', newEntity)
        } else {
          console.log ('Delete Failed!!!')
        }
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  newEntitySetName ({ commit, dispatch }, entityName) {
    dispatch('resetDrilldownEntity')
    commit('setDrilldownEntityName', entityName)
  },
  createEntityWithName ({ dispatch }, entityName) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dispatch('newEntitySetName', entityName)
      dispatch('createEntity').then(resp => resolve(resp))
    })
  },
  createEntity ({ state, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      entitiesApi.postEntity(state.drilldownEntity).then(resp => {
        if (resp) {
          commit('addEntity', resp)
        }
        dispatch('deactiveLoading', null, { root : true })
        dispatch('resetDrilldownEntity')
        resolve(resp)
      })
    })
  },
  setSectorCodes ({ commit }, sectorCodes) {
    commit('setSectorCodeList', sectorCodes)
  },
}


const mutations = {
  addEntity (state, entity) {
    state.entities.unshift(entity)
  },
  removeEntityFromList (state, entityId) {
    const index = state.entities.findIndex(entity => entity.id === entityId)
    if (index >= 0) {
      state.entities.splice(index, 1)
    }
  },
  setDrilldownEntityName (state, name) {
    state.drilldownEntity.entity_name = name
  },
  setEntities (state, fromApi) {
    state.entities = fromApi
  },
  setIndustries (state, fromApi) {
    state.industries = fromApi
  },
  setVentureIndustries (state, fromApi) {
    state.ventureIndustries = fromApi
  },
  setDrilldownEntity (state, entity) {
    state.drilldownEntity = entity
  },
  updateDrilldownName (state, entityName) {
    state.drilldownEntity.entity_name = entityName
  },
  updateDrilldownInList (state, entity)  {
    const index = state.entities.findIndex(c => c.id === entity.id)
    if (index >= 0) {
      state.entities.splice(index, 1, entity)
    }
  },
  setSectorCodeList (state, sectorCodes) {
    state.sectorCodes = sectorCodes
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
