import base from './base'

const getDailyPerformances = (date) => base
  .get('/daily_performances/', {
    params: {
      date: date
    }
  }).then(resp => resp.data)

const updateDailyPerformances = (formData) => base
  .patch('/daily_performances/', { daily_performances: formData }).then(resp => resp.data)

const uploadDailyPerformancesFile = (formData, contentType='multipart/form-data') => base
  .post('/daily_performances/upload_daily_performances_file', formData, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const importMiraeDailyPerformances = (date) => base
  .post('/daily_performances/import_mirae_daily_performances', { date: date }).then(resp => resp.data)

export default {
  getDailyPerformances: getDailyPerformances,
  updateDailyPerformances: updateDailyPerformances,
  uploadDailyPerformancesFile: uploadDailyPerformancesFile,
  importMiraeDailyPerformances: importMiraeDailyPerformances,
}
