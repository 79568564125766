import base from './base'

const getSchedules = (filters) => base
  .get('/schedules/', { params: filters }).then(resp => resp.data)

const getSchedule = (scheduleId) => base
  .get(`/schedules/${scheduleId}`).then(resp => resp.data)

const postSchedule = (scheduleParams, contentType='application/json') => base
.post('/schedules/', scheduleParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateSchedule = (scheduleId, scheduleParams, contentType='application/json') => base
.patch(`/schedules/${scheduleId}`, scheduleParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteSchedule = (scheduleId) => base
.delete(`/schedules/${scheduleId}`).then(resp => resp.data)

const getLedgerSchedules = (paramData) => base
  .get('/schedules/ledger_schedule', { params: paramData }).then(resp => resp.data)

export default {
  deleteSchedule: deleteSchedule,
  getSchedules: getSchedules,
  getSchedule: getSchedule,
  postSchedule: postSchedule,
  updateSchedule: updateSchedule,
  getLedgerSchedules: getLedgerSchedules
}
