import Vue from 'vue'
import Vuex from 'vuex'
import changeLogsApi from '@/api/v1/change_logs'

Vue.use(Vuex)

const state = () => ({
  changeLogs: [],
  sharedComment: {}
})


const actions = {
  getChangeLogs ({ commit }, investmentId) {
    changeLogsApi.getChangeLogs(investmentId).then(resp => {
      commit('setChangeLogs', resp.data)
    })
  },
  getCommentById ({ commit }, commentId) {
    changeLogsApi.getCommentById(commentId).then(resp => {
      commit('setSharedComment', resp.change_log)
    })
  },
  filterChangeLogs ({ commit }, filterParams) {
    changeLogsApi.getChangeLogs(filterParams.drilldownInvestmentId, filterParams.filterText, filterParams.customFieldTemplateId).then(resp => {
      commit('setChangeLogs', resp.data)
    })
  },
  createComment ({ dispatch }, comment) {
    let data = new FormData()
    data.append('investment_id', comment.investmentId)
    data.append('value', comment.value)
    data.append('comment_file', comment.attachedFile)
    return new Promise(resolve => {
      changeLogsApi.createComment(data).then(resp => {
        resolve(resp)
        dispatch('getChangeLogs', comment.investmentId)
      })
    })
  },
  updateCommentQuick ({ commit }, comment) {
    return new Promise(resolve => {
      changeLogsApi.updateComment(comment).then(resp => {
        resolve(resp)
        commit('updateChangeLog', resp)
      })
    })
  },
  updateComment ({ dispatch, commit }, comment) {
    return new Promise(resolve => {
      changeLogsApi.updateComment(comment).then(resp => {
        resolve(resp)
        commit('updateChangeLog', resp)
        dispatch('getChangeLogs', comment.investmentId)
      })
    })
  },
  deleteComment ({ dispatch }, comment) {
    return new Promise(resolve => {
      changeLogsApi.deleteComment(comment.id).then(resp => {
        resolve(resp)
        dispatch('getChangeLogs', comment.investmentId)
      })
    })
  },
}

const mutations = {
  setChangeLogs (state, fromApi) {
    fromApi.forEach(log => log.editing = false)
    state.changeLogs = fromApi
  },
  setSharedComment (state, fromApi) {
    state.sharedComment = fromApi
  },
  updateChangeLog (state, log) {
    let index = state.changeLogs.findIndex(slog => slog.id === log.id)
    if (index) {
      state.changeLogs.splice(index, 1, log)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
