import base from './base'

const getRefixingSchedules = (filters) => base
  .get('/refixing_schedules/', { params: filters }).then(resp => resp.data)

const getRefixingSchedule = (refixingId) => base
  .get(`/refixing_schedules/${refixingId}`).then(resp => resp.data)

const postRefixingSchedule = (scheduleParams) => base
  .post('/refixing_schedules/', scheduleParams).then(resp => resp.data)

const patchRefixingSchedule = (refixingId, scheduleParams) => base
  .patch(`/refixing_schedules/${refixingId}`, scheduleParams).then(resp => resp.data)

const deleteRefixingSchedule = (refixingId) => base
  .delete(`/refixing_schedules/${refixingId}`).then(resp => resp.data)

const getConversionRightSchedules = (filters) => base
  .get('/conversion_right_schedules/', { params: filters }).then(resp => resp.data)

const postConversionRightSchedule = (optionParams) => base
  .post('/conversion_right_schedules/', optionParams).then(resp => resp.data)

const patchConversionRightSchedule = (optionScheduleId, optionScheduleParams) => base
  .patch(`/conversion_right_schedules/${optionScheduleId}`, optionScheduleParams).then(resp => resp.data)

const deleteConversionRightSchedule = (optionScheduleId) => base
  .delete(`/conversion_right_schedules/${optionScheduleId}`).then(resp => resp.data)

export default {
  getConversionRightSchedules: getConversionRightSchedules,
  postConversionRightSchedule: postConversionRightSchedule,
  patchConversionRightSchedule: patchConversionRightSchedule,
  deleteConversionRightSchedule: deleteConversionRightSchedule,
  getRefixingSchedules: getRefixingSchedules,
  getRefixingSchedule: getRefixingSchedule,
  postRefixingSchedule: postRefixingSchedule,
  patchRefixingSchedule: patchRefixingSchedule,
  deleteRefixingSchedule: deleteRefixingSchedule,
}
