import base from './base'

const getNewdealItems = (filters) => base
  .get('/newdeal_items/', { params: filters }).then(resp => resp.data)

const updateNewdealItem = (newdealItem) => base
  .patch(`/newdeal_items/${newdealItem.code}`, { newdeal: newdealItem }).then(resp => resp.data)

const createNewdealItem = (newdealItem) => base
  .post('/newdeal_items/', { newdeal: newdealItem }).then(resp => resp.data)

const deleteNewdealItem = (newdealItem) => base
  .delete(`/newdeal_items/${ newdealItem.item_code }`).then(resp => resp.data)

export default {
  getNewdealItems: getNewdealItems,
  createNewdealItem: createNewdealItem,
  updateNewdealItem: updateNewdealItem,
  deleteNewdealItem: deleteNewdealItem,
}
