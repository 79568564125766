import Vue                       from 'vue'
import Vuex                      from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import properSettingsApi         from '@/api/v1/proper_settings'
// import cloneDeep                 from 'lodash/cloneDeep'
// import dayjs                     from 'dayjs'
const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

Vue.use(Vuex)


const state = () => ({
  properSettings: [],
})

const getters = {
  getField,
}

const actions = {
  getProperSettings ({ commit, dispatch }, name) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      properSettingsApi.getProperSettings({params: name}).then(resp => {
        let fromApi = camelcaseKeys(resp, { deep: true })
        commit('setProperSettings', fromApi)
        dispatch('deactiveLoading', null, { root : true })
        resolve(fromApi)
      })
    })
  },
  patchProperSetting ({ state, dispatch }, portfolioId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let found = state.properSettings.find(portfolio => portfolio.id === portfolioId)
      if (found) {
        let portfolio = snakecaseKeys(found, { deep: true })
        properSettingsApi.patchProperSetting(portfolio).then(resp => {
          alert(resp)
          dispatch('deactiveLoading', null, { root : true })
          resolve(resp)
        })
      }
    })
  },
}

const mutations = {
  setProperSettings (state, fromApi) {
    state.properSettings = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
