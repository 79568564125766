import base from './base'

const getFinanceInformations = (entityId) => base
  .get(`/finance_informations/entity_entries/?entity_id=${entityId}`).then(resp => resp.data)

const getFinanceInformation = (financeInformationId) => base
  .get(`/finance_informations/${financeInformationId}`).then(resp => resp.data)

const createFinanceInformation = (financeInformationParams, contentType='application/json') => base
.post('/finance_informations/', financeInformationParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateFinanceInformation = (financeInformationId, financeInformationParams, contentType='application/json') => base
.patch(`/finance_informations/${financeInformationId}`, financeInformationParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteFinanceInformation = (financeInformationId) => base
.delete(`/finance_informations/${financeInformationId}`).then(resp => resp.data)

export default {
  getFinanceInformations: getFinanceInformations,
  getFinanceInformation: getFinanceInformation,
  createFinanceInformation: createFinanceInformation,
  updateFinanceInformation: updateFinanceInformation,
  deleteFinanceInformation: deleteFinanceInformation,
}
