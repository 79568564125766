import Vue           from 'vue'
import VueRouter     from 'vue-router'
import { authGuard } from '../auth/authGuard'
import Home          from '../views/Home.vue'
import base          from '@/api/v1/base.js'
import store from '@/store'

const CompanySettings       = () => import(/* webpackChunkName: "baseApp" */ '../views/CompanySettings.vue')
const Contacts              = () => import(/* webpackChunkName: "baseApp" */ '../views/Contacts.vue')
const CustomFieldTemplates  = () => import(/* webpackChunkName: "baseApp" */ '../views/CustomFieldTemplates.vue')
const Dashboard             = () => import(/* webpackChunkName: "baseApp" */ '../views/Dashboard.vue')
const Entities              = () => import(/* webpackChunkName: "baseApp" */ '../views/Entities.vue')
const DrilldownContainer    = () => import(/* webpackChunkName: "baseApp" */ '../views/DrilldownContainer.vue')
const NewUser               = () => import(/* webpackChunkName: "baseApp" */ '../views/NewUser.vue')
const History               = () => import(/* webpackChunkName: "baseApp" */ '../views/History.vue')
const Files                 = () => import(/* webpackChunkName: "baseApp" */ '../views/Files.vue')
const ManageAccess          = () => import(/* webpackChunkName: "baseApp" */ '../views/ManageAccess.vue')
const MyApprovals           = () => import(/* webpackChunkName: "baseApp" */ '../views/MyApprovals.vue')
const MyContact             = () => import(/* webpackChunkName: "baseApp" */ '../views/MyContact.vue')
const SetLocalStorage       = () => import(/* webpackChunkName: "baseApp" */ '../views/SetLocalStorage.vue')


const CorporateAnnouncements  = () => import(/* webpackChunkName: "modeInvestment" */ '../views/CorporateAnnouncements.vue')
const InvestmentLedger        = () => import(/* webpackChunkName: "modeInvestment" */ '../views/InvestmentLedger.vue')
const IndirectLedger          = () => import(/* webpackChunkName: "modeInvestment" */ '../views/IndirectLedger.vue')
const LedgerCalendar          = () => import(/* webpackChunkName: "modeInvestment" */ '../views/LedgerCalendar.vue')
const InvestmentOrders        = () => import(/* webpackChunkName: "modeInvestment" */ '../views/InvestmentOrders.vue')
const InvestmentPositions     = () => import(/* webpackChunkName: "modeInvestment" */ '../views/InvestmentPositions.vue')
const IndirectPositions       = () => import(/* webpackChunkName: "modeInvestment" */ '../views/IndirectPositions.vue')
const LimitedPartners         = () => import(/* webpackChunkName: "modeInvestment" */ '../views/LimitedPartners.vue')
const Funds                   = () => import(/* webpackChunkName: "modeInvestment" */ '../views/Funds.vue')
const DailyPerformances       = () => import(/* webpackChunkName: "modeInvestment" */ '../views/DailyPerformances.vue')
const Industries              = () => import(/* webpackChunkName: "modeInvestment" */ '../views/Industries.vue')
const LedgerCodes             = () => import(/* webpackChunkName: "modeInvestment" */ '../views/LedgerCodes.vue')
const LedgerPiCodes           = () => import(/* webpackChunkName: "modeInvestment" */ '../views/LedgerPiCodes.vue')
const Ipo                     = () => import(/* webpackChunkName: "modeInvestment" */ '../views/Ipo.vue')
const IpoDraft                = () => import(/* webpackChunkName: "modeInvestment" */ '../views/ipo/IpoDraft.vue')
const VentureIndustries       = () => import(/* webpackChunkName: "modeInvestment" */ '../views/VentureIndustries.vue')
const Portfolios              = () => import(/* webpackChunkName: "modeInvestment" */ '../views/Portfolios.vue')
const LpPortfolios            = () => import(/* webpackChunkName: "modeInvestment" */ '../views/LpPortfolios.vue')
const HoldingsReport          = () => import(/* webpackChunkName: "modeInvestment" */ '../views/HoldingsReport.vue')
const InvestmentReviewReport  = () => import(/* webpackChunkName: "modeInvestment" */ '../views/InvestmentReviewReport.vue') // need to remove and create real page once we get confirmation this looks good from client
const InvestmentReportMinutes = () => import(/* webpackChunkName: "modeInvestment" */ '../views/InvestmentReportMinutes.vue') // need to remove and create real page once we get confirmation this looks good from client
const ReportsSignPage         = () => import(/* webpackChunkName: "modeInvestment" */ '../views/reports/ReportsSignPage.vue') // need to remove and create real page once we get confirmation this looks good from client
const CommentView             = () => import(/* webpackChunkName: "modeInvestment" */ '../views/CommentView.vue')
const ProprietaryPortfolio    = () => import(/* webpackChunkName: "modeInvestment" */ '../views/ProprietaryPortfolio.vue')
const PiFills                 = () => import(/* webpackChunkName: "modeInvestment" */ '../views/PiFills.vue')
const PiDivs                  = () => import(/* webpackChunkName: "modeInvestment" */ '../views/PiDivs.vue')

const MobilePortfolios        = () => import(/* webpackChunkName: "mobile" */ '../views/mobile/Portfolios.vue')
const MobileIpoPortfolio      = () => import(/* webpackChunkName: "mobile" */ '../views/mobile/IpoPortfolio.vue')
const MobileIpoAllocated      = () => import(/* webpackChunkName: "mobile" */ '../views/mobile/IpoAllocated.vue')
const MobileIpoInformation    = () => import(/* webpackChunkName: "mobile" */ '../views/mobile/IpoInformation.vue')


Vue.use(VueRouter)

  const setTokenOnMobile = (to, from, next) => {
    if (to.query.l_s_keyname && to.query.l_s_keyvalue) {
      var token = to.query.l_s_keyvalue;
      base.defaults.headers.common['Authorization'] = ''
      delete base.defaults.headers.common['Authorization']
      base.defaults.headers.common['Authorization'] = token
      localStorage.setItem(to.query.l_s_keyname, to.query.l_s_keyvalue)
      updateUserData()

      next()
    }
  }

  const updateUserData = () => {
    store.dispatch('users/getCurrentUserInfoOnMobile').then(() => {
        store.dispatch('companySettings/getCompanySettings')
          .then(() => {})
          .catch((error) => {
            console.error('Error fetching `getCompanySettings`:', error)
          })
      })
      .catch((error) => {
        console.error('Error fetching `getCurrentUserInfoOnMobile`:', error)
      })
  }

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/company_settings',
    name: 'CompanySettings',
    component: CompanySettings,
    beforeEnter: authGuard,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    beforeEnter: authGuard,
  },
  {
    path: '/my_contact',
    name: 'MyContact',
    component: MyContact,
    beforeEnter: authGuard,
  },
  {
    path: '/corporate_announcements',
    name: 'CorporateAnnouncements',
    component: CorporateAnnouncements,
    beforeEnter: authGuard,
  },
  {
    path: '/portfolios',
    name: 'Portfolios',
    component: Portfolios,
    beforeEnter: authGuard,
  },
  {
    path: '/proprietary-portfolio',
    name: 'ProprietaryPortfolio',
    component: ProprietaryPortfolio,
    beforeEnter: authGuard,
  },
  {
    path: '/pi-fills',
    name: 'PiFills',
    component: PiFills,
    beforeEnter: authGuard,
  },
  {
    path: '/pi-divs',
    name: 'PiDivs',
    component: PiDivs,
    beforeEnter: authGuard,
  },
  {
    path: '/ledger_pi_codes',
    name: 'LedgerPiCodes',
    component: LedgerPiCodes,
    beforeEnter: authGuard,
  },
  {
    path: '/lp_portfolios',
    name: 'LpPortfolios',
    component: LpPortfolios,
    beforeEnter: authGuard,
  },
  {
    path: '/holdings_report',
    name: 'HoldingsReport',
    component: HoldingsReport,
    beforeEnter: authGuard,
  },
  {
    path: '/custom_field_templates',
    name: 'CustomFieldTemplates',
    component: CustomFieldTemplates,
    beforeEnter: authGuard,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/drilldown',
    name: 'DrilldownContainer',
    component: DrilldownContainer,
    beforeEnter: authGuard,
  },
  {
    path: '/entities',
    name: 'Entities',
    component: Entities,
    beforeEnter: authGuard,
  },
  {
    path: '/investments',
    name: 'Investments',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/new_user',
    name: 'NewUser',
    component: NewUser,
    beforeEnter: authGuard,
  },
  {
    path: '/investment_ledger',
    name: 'InvestmentLedger',
    component: InvestmentLedger,
    beforeEnter: authGuard,
  },
  {
    path: '/indirect_ledger',
    name: 'IndirectLedger',
    component: IndirectLedger,
    beforeEnter: authGuard,
  },
  {
    path: '/ledger_calendar',
    name: 'LedgerCalendar',
    component: LedgerCalendar,
    beforeEnter: authGuard,
  },
  {
    path: '/investment_orders',
    name: 'InvestmentOrders',
    component: InvestmentOrders,
    beforeEnter: authGuard,
  },
  {
    path: '/investment_positions',
    name: 'InvestmentPositions',
    component: InvestmentPositions,
    beforeEnter: authGuard,
  },
  {
    path: '/indirect_positions',
    name: 'IndirectPositions',
    component: IndirectPositions,
    beforeEnter: authGuard,
  },
  {
    path: '/limited_partners',
    name: 'LimitedPartners',
    component: LimitedPartners,
    beforeEnter: authGuard,
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    beforeEnter: authGuard,
  },
  {
    path: '/files',
    name: 'Files',
    component: Files,
    beforeEnter: authGuard,
  },
  {
    path: '/funds',
    name: 'Funds',
    component: Funds,
    beforeEnter: authGuard,
  },
  {
    path: '/daily_performances',
    name: 'DailyPerformances',
    component: DailyPerformances,
    beforeEnter: authGuard,
  },
  {
    path: '/industries',
    name: 'Industries',
    component: Industries,
    beforeEnter: authGuard,
  },
  {
    path: '/ledger_codes',
    name: 'LedgerCodes',
    component: LedgerCodes,
    beforeEnter: authGuard,
  },
  {
    path: '/ipo',
    name: 'Ipo',
    component: Ipo,
    beforeEnter: authGuard,
  },
  {
    path: '/manage_access',
    name: 'ManageAccess',
    component: ManageAccess,
    beforeEnter: authGuard,
  },
  {
    path: '/my_approvals',
    name: 'MyApprovals',
    component: MyApprovals,
    beforeEnter: authGuard,
  },
  {
    path: '/investment-review-report',
    name: 'InvestmentReviewReport',
    component: InvestmentReviewReport,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/investment-report-minutes',
    name: 'InvestmentReportMinutes',
    component: InvestmentReportMinutes,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/reports/reports-sign-page',
    name: 'ReportsSignPage',
    component: ReportsSignPage,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/ipo/ipo-draft',
    name: 'IpoDraft',
    component: IpoDraft,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/venture_industries',
    name: 'VentureIndustries',
    component: VentureIndustries,
    beforeEnter: authGuard,
  },
  {
    path: '/mobile/portfolios',
    name: 'MobilePortfolios',
    component: MobilePortfolios,
    beforeEnter: setTokenOnMobile,
  },
  {
    path: '/mobile/ipo-portfolio',
    name: 'MobileIpoPortfolio',
    component: MobileIpoPortfolio,
    beforeEnter: setTokenOnMobile,
  },
  {
    path: '/mobile/ipo-allocated',
    name: 'MobileIpoAllocated',
    component: MobileIpoAllocated,
    beforeEnter: setTokenOnMobile,
  },
  {
    path: '/mobile/ipo-information',
    name: 'MobileIpoInformation',
    component: MobileIpoInformation,
    beforeEnter: setTokenOnMobile,
  },
  {
    path: '/set-local-storage',
    name: 'SetLocalStorage',
    component: SetLocalStorage,
  },
  {
    path: '/comment-view',
    name: 'CommentView',
    component: CommentView,  
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
